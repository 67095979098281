import CryptoJS from "crypto-js";

export const decryptData = (encryptedData) => {
  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: CryptoJS.enc.Hex.parse(encryptedData) },
    CryptoJS.enc.Utf8.parse(process.env.REACT_APP_SECRET_KEY),
    {
      iv: CryptoJS.enc.Hex.parse(process.env.REACT_APP_SECRET_IV),
    }
  ).toString(CryptoJS.enc.Utf8);

  return JSON.parse(decrypted)?.data;
};
