import React from "react";
import Hero from "../components/FAQ/Hero";
import Support from "../components/FAQ/Support";
import Box from "@mui/material/Box";
import CalltoActionFaq from "../components/FAQ/CalltoActionFaq";
import OpenRoles from "../components/FAQ/OpenRoles";
import CalltoAction from "../components/CalltoAction";
import DrwaerComponent from "../components/DrawerComponent";
import Footer from "../components/Footer";

const FAQ = () => {
  return (
    <Box>
      <DrwaerComponent />
      <Box sx={{ paddingTop: "150px" }}>
        {/* <Hero /> */}
        <Support />
        <CalltoActionFaq />
        <OpenRoles />
        <CalltoAction />
        <Footer />
      </Box>
    </Box>
  );
};

export default FAQ;
