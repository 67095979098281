import React from "react";
// import React from "react";
// import logo from "./logo.svg";
import { BrowserRouter } from "react-router-dom";
// import "./App.css";
import Navbar from "../components/Navbar";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { appTheme } from "../theme/theme";
import HeroSection from "../components/HeroSection";
import { Box, Button, Paper } from "@mui/material";
import Image from "../assets/img/backgroundImg.png";
import Features from "../components/Features";
import Working from "../components/Working";
import Reviews from "../components/Reviews";
import CalltoAction from "../components/CalltoAction";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer";
import Maps from "../components/ContactUsPage/Maps";
import DrawerComponent from "../components/DrawerComponent";
import { lime, purple } from "@mui/material/colors";

const styles = {
  paperContainer: {
    backgroundImage: `url(${Image})`,
    paddingBottom: "1rem",
  },
};
const theme = createTheme({
  palette: {
    primary: lime,
    secondary: purple,
  },
});

const Home = () => {
  return (
    <Box sx={{}}>
      <Paper style={styles.paperContainer}>
        <DrawerComponent />
        <HeroSection />
      </Paper>
      <Features id={"section1"} />
      <Working />
      <Reviews />
      <CalltoAction />
      <Box sx={{ paddingBottom: "10%" }}>
        <ContactUs />
        <Maps />
      </Box>

      <Footer />
    </Box>
  );
};

export default Home;
