import React from "react";
import {
  Box,
  Paper,
  Grid,
  Stack,
  Typography,
  Button,
  colors,
  Divider,
} from "@mui/material";
import { Link } from "react-router-dom";
import { experimentalStyled as styled } from "@mui/material/styles";
import logo from "../assets/img/logo.svg";
import badge from "../assets/img/Mobile app store badge.png";

import appstore from "../assets/img/AboutImg/appstore2.png";
import google from "../assets/img/AboutImg/app.png";
import insta from "../assets/img/AboutImg/insta.png";
import facebook from "../assets/img/AboutImg/Social icon.png";
import whatsapp from "../assets/img/AboutImg/whatsapp.png";
const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
}));

const Footer = () => {
  const showMap = () => {
    window.open("https://maps.google.com?q=" + 53.49202 + "," + -2.21367);
  };
  const phoneNumber = "+44 7860228320";
  const message = "Hello World!";

  const handleClick = () => {
    const whatsappLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      message
    )}&phone=${encodeURIComponent(phoneNumber)}`;
    window.open(whatsappLink, "_blank");
  };
  return (
    <Item sx={{ position: "relative" }}>
      <Grid
        container
        spacing={{ sm: 2, xs: 1 }}
        sx={{
          position: "absolute",
          borderRadius: "10px",
          height: { xs: "12%", sm: "17%", xl: "50%", md: "30%", lg: "40%" },
          width: { xl: "80%", lg: "70%", md: "70%", sm: "80%", xs: "70%" },
          padding: { xl: 10, lg: 2, md: 2 },
          backgroundColor: "#53389E",
          bottom: { lg: 500, md: 450 },
          top: { md: "-13%", xs: "-5%", sm: "-7%", lg: "-18%", xl: "-20%" },
          "@media (max-width: 321px)": {
            top: "-5%",
          },

          left: { lg: "16%", md: "18%", sm: "13%", xs: "18%", xl: "10%" },
          right: { lg: "20%", md: "8%" },
          justifyContent: "center",
          alignItems: "baseline",
        }}
        justifyContent={"center"}
        alignItems={"baseline"}
      >
        <Grid
          item
          xs={12}
          sm={6}
          lg={8}
          md={8}
          sx={{
            paddingTop: { xl: 20 },
            // backgroundColor: "green",
            marginX: { sm: 0 },
          }}
        >
          <Stack
            direction={"column"}
            spacing={{ sm: 2, xs: 0 }}
            sx={{ color: "#fff" }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: { xs: "bold" },
                fontSize: { xl: 40, lg: 30, md: 30, sm: 20, xs: 10 },
                letterSpacing: { xs: 1, sm: 0 },
                fontFamily: "IBM Plex Sans",
              }}
            >
              We’ll send you a nice letter once per week
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: { xl: 20, lg: 15, md: 15, sm: 15, xs: 8 },
                fontFamily: "Montserrat",
                fontWeight: 200,
              }}
            >
              No spam. Just the latest releases and tips, interesting articles,
              and exclusive interviews with great people.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={4} md={4} sm={6}>
          {/* sx={{ backgroundColor: "purple" }} */}
          <Stack
            direction={"row"}
            spacing={1}
            sx={{ justifyContent: { xs: "center" } }}
          >
            <a href="/contact" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                sx={{
                  padding: "4px",
                  backgroundColor: { xs: "#fff" },
                  color: "black",
                  fontSize: { xl: 30, lg: 15, sm: 12, xs: 8 },
                  width: { xl: 200, lg: 120, md: 100, xs: 60, sm: 90 },
                  height: { xl: 70, lg: 40, md: 30, xs: 20, sm: 30 },
                  "&:hover": {
                    backgroundColor: "#fff",
                  },
                }}
              >
                Learn More
              </Button>
            </a>
            <a href="/contact" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                sx={{
                  padding: "4px",
                  backgroundColor: { xs: "#C7FA56" },
                  color: "black",
                  fontSize: { xl: 30, lg: 15, sm: 12, xs: 8 },
                  width: { xl: 200, lg: 120, md: 100, xs: 60, sm: 90 },
                  height: { xl: 70, lg: 40, md: 30, xs: 20, sm: 30 },
                  "&:hover": {
                    backgroundColor: "#C7FA56",
                  },
                }}
              >
                Get Started
              </Button>
            </a>
          </Stack>
        </Grid>
      </Grid>

      <Stack
        sx={{
          height: "50%",
          backgroundColor: "#1D2939",
          paddingTop: { lg: 10 },
          margin: { md: 3, lg: 0, xl: 0 },
        }}
      >
        <Box
          sx={{
            paddingTop: { lg: 20, md: 30 },

            margin: { xl: 3, lg: 3, md: 6 },
          }}
        >
          <Grid
            container
            spacing={{ sm: 10, lg: 2, xs: 2 }}
            marginBottom={10}
            paddingTop={{ xs: 12, sm: 15, md: 0 }}
            paddingLeft={{ sm: 10, md: 0, xs: 3 }}
          >
            <Grid item xs={12} md={4} lg={4}>
              <Box
                sx={{
                  marginTop: { sm: "5%", xs: "2%" },
                }}
              >
                <Stack
                  direction={"column"}
                  spacing={{ sm: 7, xs: 3 }}
                  sx={{ marginLeft: { md: "8%" } }}
                >
                  <Stack direction={"row"} alignItems={"center"}>
                    <Box
                      component="img"
                      sx={{
                        height: 20,
                        width: 20,
                        maxHeight: { xs: 100, md: 100 },
                        maxWidth: { xs: 100, md: 100 },
                      }}
                      alt="The house from the offer."
                      src={logo}
                    />
                    <Typography
                      variant="h3"
                      sx={{
                        color: "#fff",
                        fontSize: { sm: "30px", xs: "20px" },
                      }}
                    >
                      SimlessPay
                    </Typography>
                  </Stack>
                  <Typography
                    sx={{
                      color: " #fff",
                      fontFamily: "Montserrat",
                      fontWeight: 200,
                    }}
                  >
                    SimlessPay enables easy access for people and business to
                    send and receive money globally.
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={6} md={1} lg={1} sx={{ color: "#fff" }}>
              <Stack direction={"column"} spacing={2}>
                <Typography variant="subtitle1" sx={{ color: "#B3E14D" }}>
                  Links
                </Typography>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "var(--slate-blue-tint-70, #D8CDFE",
                    fontWeight: 200,
                    fontFamily: "Montserrat",
                  }}
                  to={"/about"}
                >
                  About Us
                </Link>
                {/* <Link
                  style={{
                    textDecoration: "none",
                    color: "var(--slate-blue-tint-70, #D8CDFE",
                    fontWeight: 200,
                    fontFamily: "Montserrat",
                  }}
                  to={"/work"}
                >
                  How It works
                </Link> */}
                <Link
                  to={"/faq"}
                  style={{
                    textDecoration: "none",
                    color: "var(--slate-blue-tint-70, #D8CDFE",
                    fontWeight: 200,
                    fontFamily: "Montserrat",
                  }}
                >
                  FAQ
                </Link>
                <Link
                  to={"/blog"}
                  style={{
                    textDecoration: "none",
                    color: "var(--slate-blue-tint-70, #D8CDFE",
                    fontWeight: 200,
                    fontFamily: "Montserrat",
                  }}
                >
                  Blog
                </Link>
                <Link
                  to={"/contact"}
                  style={{
                    textDecoration: "none",
                    color: "var(--slate-blue-tint-70, #D8CDFE",
                    fontWeight: 200,
                    fontFamily: "Montserrat",
                  }}
                >
                  Contact{" "}
                </Link>
              </Stack>
            </Grid>
            <Grid item xs={6} md={1} lg={2} sx={{ color: "#fff" }}>
              <Stack direction={"column"} spacing={2}>
                <Typography variant="subtitle1" sx={{ color: "#B3E14D" }}>
                  Legal
                </Typography>
                <Link
                  to={"/privacy-policy"}
                  style={{
                    textDecoration: "none",
                    color: "var(--slate-blue-tint-70, #D8CDFE",
                    fontWeight: 200,
                    fontFamily: "Montserrat",
                  }}
                >
                  Terms Of Use
                </Link>
                <Link
                  to={"/privacy-policy"}
                  style={{
                    textDecoration: "none",
                    color: "var(--slate-blue-tint-70, #D8CDFE",
                    fontWeight: 200,
                    fontFamily: "Montserrat",
                  }}
                >
                  Privacy Policy
                </Link>
                {/* <Link
                  to={"/work"}
                  style={{
                    textDecoration: "none",
                    color: "var(--slate-blue-tint-70, #D8CDFE",
                    fontWeight: 200,
                    fontFamily: "Montserrat",
                  }}
                >
                  Complaint Policy
                </Link> */}
              </Stack>
            </Grid>
            <Grid item xs={6} md={4} lg={3} sx={{ color: "#fff" }}>
              <Stack direction={"column"} spacing={2}>
                <Typography variant="subtitle1" sx={{ color: "#B3E14D" }}>
                  Conatact
                </Typography>
                <a
                  onClick={showMap}
                  style={{
                    cursor: "pointer",
                    fontWeight: 200,
                    color: "var(--slate-blue-tint-70, #D8CDFE",
                    fontFamily: "Montserrat",
                  }}
                >
                  Location: Cariocca Business Park, 2, Sawley Road Manchester,
                  England, M40 8BB
                </a>

                <a
                  style={{
                    cursor: "pointer",
                    fontWeight: 200,
                    color: "var(--slate-blue-tint-70, #D8CDFE",
                    fontFamily: "Montserrat",
                    textDecoration: "none",
                  }}
                  href="tel:+44 734 121 5978"
                >
                  Tel: +44 734 121 5978
                </a>

                <a
                  style={{
                    cursor: "pointer",
                    fontWeight: 200,
                    color: "var(--slate-blue-tint-70, #D8CDFE",
                    fontFamily: "Montserrat",
                    textDecoration: "none",
                  }}
                  href="mailto:info@simlesspay.com"
                >
                  {" "}
                  Email: info@simlesspay.com
                </a>

                <a
                  style={{
                    fontWeight: 200,
                    color: "var(--slate-blue-tint-70, #D8CDFE",
                    fontFamily: "Montserrat",
                    textDecoration: "none",
                  }}
                >
                  {" "}
                  Working Hours: 9am-5pm
                </a>
              </Stack>
            </Grid>
            <Grid item xs={6} lg={2} md={2} sx={{ color: "white" }}>
              <Stack direction={"column"} spacing={2} ml={{ lg: 7 }}>
                <Typography variant="subtitle1">Get the App</Typography>
                <Stack direction={"column"} justifyContent={"space-between"}>
                  <Link
                    to="https://play.google.com/store/apps/details?id=com.simlesspay"
                    target="_blank"
                    style={{ paddingBottom: "1rem" }}
                  >
                    <Box
                      component="img"
                      sx={{
                        height: 30,
                        width: 100,
                        maxHeight: { xs: 233, md: 167 },
                        maxWidth: { xs: 350, md: 250 },
                      }}
                      alt="The house from the offer."
                      src={google}
                    />
                  </Link>
                  <Link
                    to="https://apps.apple.com/pk/app/simlesspay-money-transfer/id6466781453"
                    target="_blank"
                  >
                    <Box
                      component="img"
                      sx={{
                        height: 30,
                        width: 100,
                        maxHeight: { xs: 233, md: 167 },
                        maxWidth: { xs: 350, md: 250 },
                        background: "var(--gray-800, #1D2939)",
                      }}
                      alt="The house from the offer."
                      src={appstore}
                    />
                  </Link>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <Box sx={{ padding: "0 3%" }}>
            <Divider sx={{ background: "#fff", padding: "0 10%" }}></Divider>
          </Box>

          <Grid
            container
            spacing={2}
            justifyContent={"space-between"}
            alignItems={"center"}
            marginTop={"2px"}
          >
            <Grid item sm={10} xs={8} justifyContent={"start"}>
              {" "}
              <Typography
                sx={{
                  fontWeight: 200,
                  color: "var(--slate-blue-tint-70, #D8CDFE)",
                  fontSize: { xs: "12px", sm: "12px", fontWeight: 200 },
                  padding: { xs: "0 3%" },
                }}
                variant="subtitle2"
              >
                CopyRight@2023SimlessPay.All rights reserved.
              </Typography>
            </Grid>
            <Grid item sm={2} xs={4}>
              <Box sx={{ padding: "0 15%" }}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box>
                    {" "}
                    <a href="whatsapp://send?text=Hello World!&phone=+447341215978">
                      {" "}
                      <Box
                        onClick={handleClick}
                        sx={{
                          cursor: "pointer",
                        }}
                      >
                        <Box
                          component="img"
                          sx={{
                            height: { xs: 20 },
                            width: { xs: 20 },
                          }}
                          alt="The house from the offer."
                          src={whatsapp}
                        />
                      </Box>
                    </a>
                  </Box>
                  <Box>
                    <Link to={"https://www.instagram.com/simlesspay/"}>
                      <Box
                        component="img"
                        sx={{
                          height: { xs: 20 },
                          width: { xs: 20 },
                        }}
                        alt="The house from the offer."
                        src={insta}
                      />
                    </Link>
                  </Box>
                  <Box>
                    {" "}
                    <Link to="https://www.facebook.com/simlesspay">
                      <Box
                        component="img"
                        sx={{
                          height: { xs: 22 },
                          width: { xs: 22 },
                        }}
                        alt="The house from the offer."
                        src={facebook}
                      />
                    </Link>
                  </Box>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Item>
  );
};

export default Footer;
