export const accordionData = [
  {
    heading: "How do i register?",
    subHeading:
      "Step 1: Download the SimlessPay app from the Google Play Store or Apple Store ",
    subHeading2:
      " Step 2: Open the app, enter your email address, create a password, log in, and complete verification to be able to make transfers",
    subHeading3:
      " Step 3: Verify your account using your facial recognition and an approved form of ID. Accounts are verified using Veriff. You are registered once you have successfully completed and passed the verification process.",
  },
  {
    heading: "How long does it take to open an account?",
    subHeading:
      "It takes less than two minutes to register, Once your details are correct and has been confirmed..",
  },

  {
    heading: "What countries does SimlessPay support?",
    subHeading:
      "You can send and receive money from the United Kingdom to Nigeria and to other African countries very soon including but not limited to Ghana, Uganda, Tanzania and Kenya.",
  },
  {
    heading: "How do I transfer funds to my Naira account?",

    subHeading: " Step1 :Click on Send Money",
    subHeading1: "Step2 :Enter Amount",
    subHeading2: "Step3 :Add Beneficiary details",
    subHeading3: "Step4 :Confirm details",
    subHeading4: "Step5 :Choose Your Bank",
    subHeading5: "Step6 :Add a Reference (if necessary)",
    subHeading6: "Step7 :Verify the information and send",
  },
  {
    heading: "How do I earn €10 referral?",
    subHeading: "watch video",
  },
  {
    heading: "What payment methods do we accept?",
    subHeading:
      "We accept payment by open banking payments and faster payment bank transfers. Payment limits may apply depending on your bank and payment method.",
  },
];
