import user from "./assets/img/AboutImg/users.png";
import like from "./assets/img/AboutImg/likeicon.png";
import sweat from "./assets/img/AboutImg/sweatIcon.png";
import pride from "./assets/img/AboutImg/prideIcon.png";
import customer from "./assets/img/AboutImg/customerIcon.png";
import flag from "./assets/img/AboutImg/flagIcon.png";

export const WorkingdataArray = [
  {
    imgPath: user,
    heading: "Care about our team",
    subHeading:
      "Understand what matters to our employees. Give them what they need to do their best work.",
  },
  {
    imgPath: like,
    heading: "Be excellent to each other",
    subHeading:
      "No games. No bullshit. We rely on our peers to improve. Be open, honest and kind",
  },
  {
    imgPath: pride,
    heading: "Pride in what we do",
    subHeading:
      "Value quality and integrity in everything we do. At all times. No exceptions.",
  },
  {
    imgPath: customer,
    heading: "Pride in what we do",
    subHeading:
      "Value quality and integrity in everything we do. At all times. No exceptions.",
  },
  {
    imgPath: flag,
    heading: "Do the impossible",
    subHeading:
      'Be energized by difficult problems. Revel in unknowns. Ask "Why?", but always question, "Why not?"',
  },

  {
    imgPath: sweat,
    heading: "Don't #!&$ the customer",
    subHeading:
      "Understand customers' stated and unstated needs. Make them wildly successful.",
  },
];
