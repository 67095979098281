import React from "react";
import { Grid, Stack, Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CalltoAction = () => {
  const navigate = useNavigate();
  const navigateToContacts = () => {
    // 👇️ navigate to /contacts
    navigate("/contact");
  };
  return (
    <Box
      marginBottom={{ sm: 10, xs: 5 }}
      sx={{
        flexGrow: 1,
        px: { lg: 20, md: 10, sm: 5, xs: 5 },
        py: { xl: 10, xs: 7 },
      }}
    >
      <Stack
        direction={"column"}
        spacing={{ sm: 2, xl: 4 }}
        sx={{
          borderRadius: "16px",
          padding: { sm: "64px", xs: "30px" },

          backgroundColor: "#F9F5FF",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <Typography
          // variant="h5"
          sx={{
            fontSize: "96px",
            fontWeight: 700,
            lineHeight: "60px",
            letterSpacing: "-0.96px",
            fontSize: "50px",
          }}
        >
          Still have any question?
        </Typography> */}
        <Typography
          variant="h3"
          sx={{
            color: " var(--primary-900, #42307D)",
            fontWeight: 600,
            lineHeight: "44px",
            fontSize: { sm: "36px", xs: "20px", xl: "46px" },
            fontWeight: 700,
            letterSpacing: "-0.72px",
            fontFamily: "IBM Plex Sans",
          }}
        >
          Still have any question
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            color: "var(--primary-700, #6941C6)",
            fontSize: { sm: "20px", xl: "30px" },
            fontWeight: 400,
            lineHeight: "30px",
            fontFamily: "Montserrat",
          }}
        >
          Join over 4,000+ users already using SimlessPay
        </Typography>
        <Stack direction={{ sm: "row", xs: "column" }} spacing={2}>
          <Button
            onClick={navigateToContacts}
            variant="outlined"
            sx={{
              fontSize: { sm: "16px", xl: "26px" },
              fontFamily: "Montserrat",
              borderRadius: "8px",
              borderColor: "#D0D5DD",
              color: "#344054",
              backgroundColor: "#fff",
              "&:hover": {
                backgroundColor: "#fff",
              },
            }}
          >
            Email us
          </Button>
          <Button
            onClick={navigateToContacts}
            variant="outlined"
            sx={{
              fontSize: { sm: "16px", xl: "26px" },
              fontFamily: "Montserrat",
              borderRadius: "8px",
              borderColor: "#D0D5DD",
              color: "#fff",
              backgroundColor: "#7F56D9",
              "&:hover": {
                backgroundColor: "#7F56D9",
              },
            }}
          >
            Chat to our team
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default CalltoAction;
