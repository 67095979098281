import { Grid, Box, Stack, Typography } from "@mui/material/";
import bgImg from "../../assets/img/Faq/faq1.png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
// import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { accordionData } from "../../accordian";
import { useState } from "react";
import React from "react";

import IconButton from "@material-ui/core/IconButton";
import {
  FormControl,
  InputAdornment,
  TextField,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

import { hover } from "@testing-library/user-event/dist/hover";
// import { hover } from "@testing-library/user-event/dist/hover";
const useStyles = makeStyles(() => {
  return createStyles({
    search: {
      margin: "0",
      color: "red",
      "&:hover": "grey",
      "&:active": "grey",
    },
    icon: { color: "grey" },
  });
});

const Support = () => {
  const initialFaqList: string[][] = [];
  const { search, icon } = useStyles();
  const [showClearIcon, setShowClearIcon] = useState("none");
  const [data, setData] = useState("");
  const [searchFaq, setSearchFaq] = useState("");

  const [Faq, setFaq] = useState(accordionData);
  console.log(Faq, typeof Faq, "faqinitial");
  console.log("Hello");
  // const [faqList, setfaqList] = useState(initialFaqList);
  // const [label, setLabel] = useState(true);

  console.log("serchFaq", searchFaq);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): any => {
    const inputData = event.target.value;
    console.log("input --- >");
    setShowClearIcon(event.target.value === "" ? "none" : "flex");
    setData(event.target.value);
    setSearchFaq(event.target.value);
    if (event.target.value) {
      const filteredArray = accordionData.filter((item) => {
        return item.heading.toLowerCase().includes(searchFaq.toLowerCase());
      });
      console.log("filteredArray", filteredArray);

      setFaq(filteredArray);
    } else {
      setFaq(accordionData);
    }
  };

  const handleClick = (): void => {
    console.log("clicked the clear icon...");
    setData("");
    setFaq(accordionData);
  };
  const [open, setOpen] = useState(0);
  const handleclick = (val: any) => {
    setOpen(val === open ? "" : val);
    console.log("open", open, "value", val);
  };

  return (
    <Box>
      <Box
        sx={{
          flexGrow: 1,
          px: { lg: 20, md: 10, sm: 5, xs: 5 },
          py: { xl: 10, xs: 1 },

          paddingTop: "2rem",
        }}
      >
        <Typography
          sx={{
            color: "#7C57FC",
            fontWeight: 600,
            lineHeight: "24px",
            fontSize: { sm: "16px", xl: "38px" },
            marginBottom: { xl: 3 },
            fontFamily: "Montserrat",
          }}
        >
          FAQ,s
        </Typography>
        <Grid container spacing={{ sm: 2, xs: 5 }} mb={3}>
          <Grid item sm={6} xs={12}>
            <Typography
              variant="h2"
              sx={{
                color: "#101828",
                fontSize: { md: "48px", sm: "35px", xl: "60px", xs: "20px" },
                fontWeight: 700,
                lineHeight: "60px",
                letterSpacing: "-0.96px",
                fontFamily: "IBM Plex Sans",
              }}
            >
              Frequently asked questions
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="subtitle2"
              sx={{
                color: "#667085",

                fontWeight: "400px",
                fontFamily: "Montserrat",
                fontSize: { sx: "18px", xl: "38px" },
                lineHeight: { sm: "30px", xl: "56px" },
                marginBottom: { xl: 3 },
              }}
            >
              Everything you need to know about the product and billing. Can’t
              find the answer you’re looking for? Please chat to our friendly
              team.
            </Typography>
            <FormControl className={search}>
              <TextField
                placeholder="Search"
                className={search}
                size="small"
                variant="outlined"
                value={data}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Stack direction={"row"}>
                        {data ? null : <SearchIcon className={icon} />}
                      </Stack>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{ display: showClearIcon }}
                      onClick={handleClick}
                    >
                      {data ? <ClearIcon className={icon} /> : null}
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box position={"relative"}>
        <Box
          component="img"
          sx={{
            height: { sm: 333, xl: 460, xs: 100 },
            width: { sm: 400, xl: 550, xs: 100 },
            display: { xs: "none", sm: "block" },
          }}
          alt="The house from the offer."
          src={bgImg}
          position={"absolute"}
          top={"50%"}
        />
        <Box
          sx={{
            flexGrow: 1,
            px: { md: 20, sm: 10, xs: 10 },
            py: { xl: 10, sm: 2, xs: 2 },
          }}
        ></Box>

        <Box
          sx={{
            flexGrow: 1,
            px: { lg: 20, md: 10, sm: 5, xs: 5 },
          }}
        >
          <Grid container spacing={2}>
            <Grid item sm={4} xs={12}>
              <Stack direction={"column"} spacing={{ sm: 3, xs: 0 }}>
                <Typography
                  sx={{
                    color: "#7C57FC",
                    fontWeight: 600,
                    lineHeight: "24px",
                    fontSize: { sx: "36px", xl: "38px" },
                    marginBottom: { xl: 3 },
                  }}
                >
                  Support
                </Typography>
                <Typography
                  variant="h2"
                  sx={{
                    color: "#101828",
                    fontSize: {
                      md: "48px",
                      sm: "35px",
                      xl: "60px",
                      xs: "20px",
                    },
                    fontWeight: 700,
                    lineHeight: "60px",
                    letterSpacing: "-0.96px",
                  }}
                >
                  FAQ,s
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "#667085",

                    fontWeight: "400px",
                    fontFamily: "Montserrat",
                    fontSize: { sx: "18px", xl: "38px" },
                    lineHeight: { sm: "30px", xl: "56px" },
                    marginBottom: { xl: 3 },
                  }}
                >
                  Everything you need to know about the product and billing.
                  Can’t find the answer you’re looking for? Please chat to our
                  friendly team.
                </Typography>
              </Stack>
            </Grid>
            <Grid item sm={8} xs={12}>
              <div>
                <div style={{ width: "100%" }}>
                  {Faq.map((item, index) => (
                    <Accordion
                      style={{
                        background: "var(--gray-50, #F9FAFB)",
                        margin: "0px",
                        boxShadow: "none",
                      }}
                      expanded={open === index ? true : false}
                    >
                      <AccordionSummary
                        onClick={() => handleclick(index)}
                        expandIcon={
                          open === index ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                stroke="#98A2B3"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                stroke="#98A2B3"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          )
                        }
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            fontSize: { sm: "18px", xl: "28px", xs: "16px" },
                            fontWeight: 600,
                            justifyContent: "start",
                            alignItems: "start",
                            fontFamily: "Montserrat",
                          }}
                        >
                          <>{item.heading}</>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ textAlign: "start" }}>
                        <Typography
                          sx={{
                            fontSize: { sm: "16px", xl: "26px", xs: "12px" },
                            fontFamily: "Montserrat",
                          }}
                        >
                          <p> {item.subHeading}</p>

                          <p> {item.subHeading2}</p>
                          <p> {item.subHeading3}</p>
                          <p> {item.subHeading4}</p>
                          <p> {item.subHeading5}</p>
                          <p> {item.subHeading6}</p>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Support;
