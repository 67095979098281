import React from "react";
import { useState } from "react";
import { Box, Stack } from "@mui/material";
import IconButton from "@material-ui/core/IconButton";
import {
  FormControl,
  InputAdornment,
  TextField,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

import { Typography, Grid } from "@mui/material";
import { hover } from "@testing-library/user-event/dist/hover";
// import { hover } from "@testing-library/user-event/dist/hover";
const useStyles = makeStyles(() => {
  return createStyles({
    search: {
      margin: "0",
      color: "red",
      "&:hover": "grey",
      "&:active": "grey",
    },
    icon: { color: "grey" },
  });
});

const Hero = () => {
  const { search, icon } = useStyles();
  const [showClearIcon, setShowClearIcon] = useState("none");
  const [data, setData] = useState("");
  // const [searchIcon, setSearch] = useState(true);
  // const [label, setLabel] = useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const inputData = event.target.value;
    setShowClearIcon(event.target.value === "" ? "none" : "flex");
    setData(event.target.value);
    // inputData ? setSearch(false) : setSearch(true);
    // inputData ? setLabel(false) : setLabel(true);
  };

  const handleClick = (): void => {
    console.log("clicked the clear icon...");
    setData("");
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
        px: { lg: 20, md: 10, sm: 5, xs: 5 },
        py: { xl: 10, xs: 7 },

        paddingTop: "2rem",
      }}
    >
      <Typography
        sx={{
          color: "#7C57FC",
          fontWeight: 600,
          lineHeight: "24px",
          fontSize: { sm: "16px", xl: "38px" },
          marginBottom: { xl: 3 },
          fontFamily: "Montserrat",
        }}
      >
        FAQ,s
      </Typography>
      <Grid container spacing={{ sm: 2, xs: 5 }} mb={3}>
        <Grid item sm={6} xs={12}>
          <Typography
            variant="h2"
            sx={{
              color: "#101828",
              fontSize: { md: "48px", sm: "35px", xl: "60px", xs: "20px" },
              fontWeight: 700,
              lineHeight: "60px",
              letterSpacing: "-0.96px",
              fontFamily: "IBM Plex Sans",
            }}
          >
            Frequently asked questions
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="subtitle2"
            sx={{
              color: "#667085",

              fontWeight: "400px",
              fontFamily: "Montserrat",
              fontSize: { sx: "18px", xl: "38px" },
              lineHeight: { sm: "30px", xl: "56px" },
              marginBottom: { xl: 3 },
            }}
          >
            Everything you need to know about the product and billing. Can’t
            find the answer you’re looking for? Please chat to our friendly
            team.
          </Typography>
          <FormControl className={search}>
            <TextField
              className={search}
              size="small"
              variant="outlined"
              value={data}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Stack direction={"row"}>
                      {data ? null : <SearchIcon className={icon} />}
                      {data ? null : (
                        <Typography variant="subtitle2" sx={{ color: "grey" }}>
                          Search
                        </Typography>
                      )}
                    </Stack>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ display: showClearIcon }}
                    onClick={handleClick}
                  >
                    {data ? <ClearIcon className={icon} /> : null}
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Hero;
