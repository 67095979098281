import { Box } from "@material-ui/core";
import React from "react";

const Maps = () => {
  const mapStyle = {
    width: "99.5%",
    height: "35%",

    inset: 0,
    filter: "scale(100) contrast(1.2) opacity(0.4)",
  };

  return (
    <Box
      justifyContent={"center"}
      alignItems={"center"}
      sx={{
        flexGrow: 1,
        px: { md: 20, sm: 20, xs: 2 },
        py: 2,
        paddingTop: "4rem",
        height: "50%",
      }}
    >
      <Box
        style={{ width: "100%", paddingBottom: "36.25%", position: "relative" }}
      >
        <iframe
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            border: 0,
          }}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2373.6619650137472!2d-2.2156772244200327!3d53.49236946378903!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bb100b42a87cd%3A0x742c4094ebd1e448!2s2%20Sawley%20Rd%2C%20Miles%20Platting%2C%20Manchester%20M40%208DE%2C%20UK!5e0!3m2!1sen!2s!4v1695900856857!5m2!1sen!2s"
        ></iframe>
      </Box>
    </Box>
  );
};

export default Maps;
