import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import Paper from "@mui/material/Paper";
import Slide from "@mui/material/Slide";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Img1 from "../../assets/img/AboutImg/Onboarding-1.png";
import Img2 from "../../assets/img/AboutImg/Onboarding-2.png";
import Img3 from "../../assets/img/AboutImg/Onboarding-3.png";
import Img4 from "../../assets/img/AboutImg/Onboarding-4.png";
import LogIn from "../../assets/img/AboutImg/Log in.png";
import SignUp from "../../assets/img/AboutImg/Sign-up.png";
import Logout from "../../assets/img/AboutImg/Log out.png";
import Money from "../../assets/img/AboutImg/Limit.png";
import Dashboard from "../../assets/img/AboutImg/Homepage.png";
import { Link } from "react-router-dom";
import "../../assets/img/css/index.css";
import { Button, Card, CardActions, CardContent, CardMedia, Typography } from "@mui/material";
import { Stack } from "@mui/joy";
import { useEffect, useState } from "react";
import axios from "axios";
import { decryptData } from "./decrypt";



const BlogAnimate = () => {

  const [data1, setdata] = useState<any>(null);
  const [Drptdata, setDecryptedData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/smlWebsiteApi/blogs/list`,
          {
            headers: {
              "api-key": process.env.REACT_APP_SECRET_IV,
            },
          }
        );
        console.log(response);

        setdata(response?.data?.result);
        setLoading(false)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (data1) {
      const decryptedData = decryptData(data1);
      setDecryptedData(decryptedData);
      console.log("decrypted data:", decryptedData);
    }
  }, [data1]);

  const handleClick = () => {
    const phoneNumber = "+44 7860228320";
    const message = "Hello World!";
    const whatsappLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      message
    )}&phone=${encodeURIComponent(phoneNumber)}`;
    window.open(whatsappLink, "_blank");
  };
  return (
    <Box
      sx={{
        // backgroundColor: "green",
        mb: "150px",
        // position: "relative",
        mt: "150px",
        padding: "0 3%",
        marginBottom: "5%",
        position: "relative",
      }}
    >
      <Grid
        container
        spacing={5}
        sx={{ marginBottom: { sm: "200px", xs: "150px" } }}
      >
        <Grid item sm={9} xs={12}>
          {" "}
          <Box sx={{ width: { sm: "100%" } }}>
            <Box
              style={
                {
                  // top: "15%",
                  // // position: "absolute",
                  // left: 0,
                  // width: "60%",
                  // bottom: 0,
                  // right: 0,
                  // animation: "ease out 1.5s linear 1 ",
                  // WebkitAnimation: "run 1.5s linear 1",
                  // animationFillMode: "forwards",
                }
              }
            >
              <Paper
                sx={{
                  borderRadius: "30px",
                  padding: "3%",
                }}
              >
                <div style={{ padding: "20px 10px" }}>
                  <Typography
                    mb={4}
                    variant="h3"
                    sx={{
                      color: "#7C57FC",
                      fontWeight: 600,
                      lineHeight: "24px",
                      fontFamily: "Montserrat",
                      fontSize: { sm: "50px", xs: "20px" },
                    }}
                  >
                    Home Screen
                  </Typography>
                  <Typography variant="subtitle1" sx={{ color: "#101828" }}>
                    Welcome Screen
                  </Typography>
                </div>

                <Grid
                  container
                  spacing={2}
                  mb={10}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Grid item xs={12} sm={6} md={3}>
                    <Box
                      component="img"
                      sx={{
                        height: 450,
                        width: "100%",
                        maxHeight: { xs: "100%", sm: "50%" },
                        borderRadius: "4%",
                      }}
                      alt="The house from the offer."
                      src={Img1}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12} md={3}>
                    <Box
                      component="img"
                      sx={{
                        height: 450,
                        width: "100%",
                        borderRadius: "4%",
                      }}
                      alt="The house from the offer."
                      src={Img2}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12} md={3}>
                    <Box
                      component="img"
                      sx={{
                        height: 450,
                        width: "100%",
                        maxHeight: { xs: 1033, md: 4500 },
                        borderRadius: "4%",
                      }}
                      alt="The house from the offer."
                      src={Img3}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12} md={3}>
                    <Box
                      component="img"
                      sx={{
                        height: 450,
                        width: "100%",
                        borderRadius: "4%",
                      }}
                      alt="The house from the offer."
                      src={Img4}
                    />
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <Typography
                        variant="h3"
                        mb={4}
                        mt={6}
                        ml={1}
                        sx={{
                          color: "#7C57FC",
                          fontWeight: 600,
                          lineHeight: "24px",
                          fontFamily: "Montserrat",
                          fontSize: { sm: "50px", xs: "30px" },
                        }}
                      >
                        Recent Blogs
                        {
                          loading && <Typography variant="h5" textAlign="center">Loading...</Typography>
                        }
                      </Typography>
                    </Grid>
                    <Grid container ml={4}>

                      {Drptdata?.map((elm) => {
                        return (
                          <Grid item md={4} xs={12}>
                            <Card sx={{ maxWidth: 345 }}>
                              <CardMedia
                                sx={{ height: 200 }}
                                image={elm?.image}
                                title="green iguana"
                              />
                              <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                  {elm?.title}
                                </Typography>
                              </CardContent>
                              <CardActions>
                                <Button variant="contained" > <Link style={{ textDecoration: "none", color: "white" }} to={`/blog/${elm?.id}`}> Read More...</Link></Button>
                              </CardActions>
                            </Card>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                </Grid>

              </Paper>
            </Box>
          </Box>
        </Grid>
        <Grid item sm={3} xs={12}>
          <Paper
            sx={{
              position: "sticky",
              top: "20%",

              // position: "absolute",
              // width: "150px",
              // height: "450px",
              // left: "85%",
              // top: "20%",
              // animation: "moveRightToLeft 1.5s linear backwards",
              // WebkitAnimation: "run 1.5s reverse 1",
              // animationFillMode: "backwards",
              // borderRadius: "10px",
              // padding: "10px",
            }}
          >
            <Stack direction={"column"} spacing={10}>
              <Paper sx={{ padding: "10px", height: "20%" }}>
                <Link
                  to="https://www.facebook.com/simlesspay"
                  target="_blank"
                  style={{ paddingBottom: "1rem", textDecoration: "none" }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#7C57FC",
                      fontWeight: 600,
                      lineHeight: "24px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    {" "}
                    Facebook
                  </Typography>
                  <Button>Follow Us</Button>
                </Link>
              </Paper>
              <Paper sx={{ padding: "10px", height: "20%" }}>
                <Link
                  to="https://www.instagram.com/simlesspay/"
                  style={{ textDecoration: "none" }}
                >
                  {" "}
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#7C57FC",
                      fontWeight: 600,
                      lineHeight: "24px",
                      fontFamily: "Montserrat",
                      textDecoration: "none",
                    }}
                  >
                    {" "}
                    Instagram
                  </Typography>
                  <Button>Follow Us</Button>
                </Link>
              </Paper>
              <Paper sx={{ padding: "10px", height: "20%" }}>
                <a
                  href="whatsapp://send?text=Hello World!&phone=+447341215978"
                  style={{ textDecoration: "none" }}
                  onClick={handleClick}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#7C57FC",
                      fontWeight: 600,
                      lineHeight: "24px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    {" "}
                    Whatsapp
                  </Typography>{" "}
                  <Button
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <Typography style={{ color: "#7C57FC" }}>
                      Contact Us
                    </Typography>
                  </Button>
                </a>
              </Paper>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BlogAnimate;
