import img1 from "./assets/img/AboutImg/avataaars.png";
import img2 from "./assets/img/AboutImg/avataaars.png";
import img3 from "./assets/img/AboutImg/icon.png";
import Img from "./assets/img/reviewImg.png";

export const ReviewsArray = [
  {
    imgPath: img3,

    review:
      "I have tried SimlessPay and I can say for a fact that it is super quick, super easy and it is super convenient. If you are looking for an easy, fast and secure way of sending money to Nigeria, Use SimlessPay.",
    name: "--Nobuo khumalu",
  },
  {
    imgPath: img3,

    review:
      "The things that I liked about the Simlesspay is that they offer the best conversion rate to Nigeria, transfers are done instantly and they will soon offer multiple payment delivery methods.",
    name: "--Kylan Gentry",
  },
  {
    imgPath: img3,

    review:
      "Simlesspay offers the best exchange rate, all major Nigeria banks are supported with recipient validation and the transfers are done instantly.",
    name: "--Saige Fuentes.",
  },
];
