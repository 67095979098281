import { Stack, Typography, Box, Grid } from "@mui/material";
import React from "react";
import { WorkingdataArray } from "../../WorkingData";
import twitter from "./../../assets/img/AboutImg/twitter.png";
import linked from "../../assets/img/AboutImg/linkedIn.png";
import inst from "../../assets/img/AboutImg/insta.png";
import abouBackground from "../../assets/img/AboutImg/abouback.png";

const HowWork = () => {
  return (
    <Box sx={{ position: "relative", backgroundColor: "red" }}>
      <Box
        sx={{
          background:
            "var(--slate-blue-linear, linear-gradient(269deg, #7C57FC 11.88%, #4525B4 96.66%))",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            px: { md: 20, sm: 10, xs: 4 },
            py: { xl: 10, xs: 7 },
          }}
        >
          <Stack
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={2}
            mb={{ sm: 10, xs: 5 }}
          >
            <Typography
              sx={{ color: "#fff", fontWeight: 600, lineHeight: "24px" }}
            >
              Our Values
            </Typography>
            <Typography
              textAlign={"center"}
              variant="h2"
              sx={{
                color: "#fff",
                fontSize: { sm: "36px", xs: "20px" },
                fontWeight: 700,
                lineHeight: { sm: "44px", xs: "30px" },
                letterSpacing: "-0.72px",
              }}
            >
              How we work at SimlessPay
            </Typography>

            <Typography
              variant="subtitle2"
              maxWidth={"768px"}
              sx={{
                color: "#fff",
                lineHeight: { sm: "30px", xs: "20px" },
                fontSize: "18px",
                fontWeight: "400px",
                fontFamily: "Montserrat",
                fontSize: { sm: "18px", xs: "16px" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              Our shared values keep us connected and guide us as one team.
            </Typography>
          </Stack>
          <Grid
            container
            gap={4}
            sx={{ maxWidth: "auto", margin: "auto" }}
            justifyContent={"center"}
          >
            {WorkingdataArray.map((item) => (
              <Grid item xs={12} sm={5} md={4} lg={3} mb={3}>
                <Stack
                  direction={"column"}
                  spacing={2}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box
                    component="img"
                    sx={{
                      height: { xs: "30%" },
                      width: { xs: "20%" },
                      top: 0,
                    }}
                    alt="The house from the offer."
                    src={item.imgPath}
                  />
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: 600,
                      lineHeight: "24px",
                      textAlign: "center",
                      fontSize: "20px",
                    }}
                  >
                    {item.heading}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "#fff",
                      lineHeight: "28px",
                      fontSize: "16px",
                      fontWeight: "400px",
                      fontFamily: "Montserrat",
                      textAlign: "center",
                    }}
                  >
                    {item.subHeading}
                  </Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <Box
        component="img"
        sx={{
          display: { xs: "none", md: "block" },
          position: "absolute",
          height: 333,
          width: 100,
          maxHeight: { xs: 23300, md: 16700 },
          maxWidth: { xs: 350000, md: 250000 },
          bottom: 0,
          right: 0,
        }}
        alt="The house from the offer."
        src={abouBackground}
      />
    </Box>
  );
};

export default HowWork;
