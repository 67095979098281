import { Grid, Box, Stack, Typography, Button } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";

import frame1 from "../assets/img/frame1.png";
import checkIcon from "../assets/img/Check icon.png";
import imgRight from "../assets/img/workingSectionImgRight.png";
import workingIcon from "../assets/img/workingIcon.png";
import workingBackground from "../assets/img/workingBackgrounImg.png";
const Item = styled(Box)(({ theme }) => ({}));
const Item2 = styled(Typography)(({ theme }) => ({
  color: theme.typography.h6.color,
}));
const gradientStyle2 = {
  background:
    "var(--Linear-2, linear-gradient(102deg, #59E980 -4.78%, #CBFD55 129.83%))",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
};
const Working = () => {
  return (
    <Box sx={{ position: "relative", backgroundColor: "yellow" }}>
      <Grid
        container
        spacing={{ xl: 10, lg: 2, md: 5 }}
        sx={{
          paddingLeft: { lg: "5%" },
          background:
            "linear-gradient(269deg, #7C57FC 11.88%, #4525B4 96.66%);",
          marginBottom: "10%",
          "@media (max-width: 900px)": {
            justifyContent: "center",
          },
        }}
      >
        <Grid item lg={6} md={5}>
          <Item>
            <Stack
              sx={{
                "@media (max-width: 900px)": {
                  justifyContent: "center",
                },
              }}
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Item2>
                <Typography
                  variant="h3"
                  sx={{
                    fontFamily: "IBM Plex Sans",
                    fontSize: { xl: 60, lg: 40, md: 35, sm: 30, xs: 25 },
                    color: "#fff",
                    letterSpacing: { lg: 1 },
                    marginTop: { xs: "10%" },
                    fontWeight: 500,
                    "@media(max-width: 300px)": {
                      fontSize: { xs: 20 },
                    },
                  }}
                >
                  How <span style={gradientStyle2}>SimlessPay</span> works{" "}
                </Typography>
              </Item2>

              <Box
                component="img"
                sx={{
                  width: { lg: "650px", md: "500px", sm: "400px", xs: "300px" },

                  "@media (max-width: 320px)": {
                    width: { xs: 250, sm: 600 },
                  },
                  "@media (max-width: 250px)": {
                    width: { xs: 200, sm: 400 },
                  },
                }}
                alt="The house from the offer."
                src={frame1}
              />
            </Stack>
          </Item>
        </Grid>
        <Grid item lg={6} md={5} mt={{ lg: 20, md: 13, sm: 10, xs: 5 }}>
          <Item
            sx={{
              width: { xs: 300, sm: 400, xl: 650 },
              "@media (max-width: 320px)": {
                width: { xs: 250, sm: 400 },
              },
              "@media (max-width: 250px)": {
                width: { xs: 200, sm: 400 },
              },
              lineHeight: { xl: 1.5 },
            }}
          >
            <Stack
              direction={"column"}
              spacing={5}
              sx={{
                marginTop: "20%",
                "@media (max-width: 900px)": {
                  marginTop: "0%",
                },
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontSize: { xs: 20, xl: 40, sm: 30 },
                  color: "#fff",
                  letterSpacing: { xl: 2, lg: 1 },
                  fontFamily: "IBM Plex Sans",
                  fontWeight: 500,
                  "@media(max-width: 300px)": {
                    fontSize: { xs: 15 },
                  },
                  "@media (max-width: 250px)": {
                    width: { xs: 200, sm: 400 },
                  },
                }}
              >
                SimlessPay has different ways to work
              </Typography>
              <Stack direction={"column"} spacing={2}>
                <Stack
                  direction={"row"}
                  spacing={2}
                  width={"fit-content"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  // sx={{ backgroundColor: "red" }}
                >
                  <Box
                    component="img"
                    sx={{
                      height: { sm: 30, xs: 20 },
                      width: { sm: 30, xs: 20 },
                      maxHeight: { xs: 233, md: 167 },
                      maxWidth: { xs: 350, md: 250 },
                    }}
                    alt="The house from the offer."
                    src={checkIcon}
                  />
                  <Item2>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: { xl: 30, sm: 15, xs: 11, md: 18 },
                        color: "#fff",
                        letterSpacing: { xl: 1 },
                        fontFamily: "IBM Plex Sans",
                        fontWeight: 500,
                        lineHeight: { xs: 1.2 },
                      }}
                    >
                      How to send money to Nigeria from the UK
                    </Typography>
                  </Item2>
                </Stack>
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"center"}
                  alignItems={"center"}
                  width={"fit-content"}
                >
                  <Box
                    component="img"
                    sx={{
                      height: { sm: 30, xs: 20 },
                      width: { sm: 30, xs: 20 },
                      maxHeight: { xs: 233, md: 167 },
                      maxWidth: { xs: 350, md: 250 },
                    }}
                    alt="The house from the offer."
                    src={checkIcon}
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: { xl: 30, sm: 15, xs: 11, md: 18 },
                      color: "#fff",
                      letterSpacing: { xl: 1 },
                      fontFamily: "IBM Plex Sans",
                      fontWeight: 500,
                      lineHeight: { xs: 1.2 },
                    }}
                  >
                    How to send money to UK from Nigeria
                  </Typography>
                </Stack>
                {/* <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"center"}
                  alignItems={"center"}
                  width={"fit-content"}
                >
                  <Box
                    component="img"
                    sx={{
                      height: { sm: 30, xs: 20 },
                      width: { sm: 30, xs: 20 },
                      maxHeight: { xs: 233, md: 167 },
                      maxWidth: { xs: 350, md: 250 },
                    }}
                    alt="The house from the offer."
                    src={checkIcon}
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: { xl: 30, sm: 15, xs: 11, md: 18 },
                      color: "#fff",
                      letterSpacing: { xl: 1 },
                      fontFamily: "IBM Plex Sans",
                      fontWeight: 500,
                      lineHeight: { xs: 1.2 },
                    }}
                  >
                    How to Send Money to Africa Instantly
                  </Typography>
                </Stack> */}
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"center"}
                  alignItems={"center"}
                  width={"fit-content"}
                >
                  <Box
                    component="img"
                    sx={{
                      height: { sm: 30, xs: 20 },
                      width: { sm: 30, xs: 20 },
                      maxHeight: { xs: 233, md: 167 },
                      maxWidth: { xs: 350, md: 250 },
                    }}
                    alt="The house from the offer."
                    src={checkIcon}
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: { xl: 30, sm: 15, xs: 11, md: 18 },
                      color: "#fff",
                      letterSpacing: { xl: 1 },
                      fontFamily: "IBM Plex Sans",
                      fontWeight: 500,
                      lineHeight: { xs: 1.2 },
                    }}
                  >
                    How to Contact Customer Support on SimlessPay
                  </Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"center"}
                  alignItems={"center"}
                  width={"fit-content"}
                  paddingBottom={"10px"}
                >
                  <Box
                    component="img"
                    sx={{
                      height: { sm: 30, xs: 20 },
                      width: { sm: 30, xs: 20 },
                      maxHeight: { xs: 233, md: 167 },
                      maxWidth: { xs: 350, md: 250 },
                    }}
                    alt="The house from the offer."
                    src={checkIcon}
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: { xl: 30, sm: 15, xs: 11, md: 18 },
                      color: "#fff",
                      letterSpacing: { xl: 1 },
                      fontFamily: "IBM Plex Sans",
                      fontWeight: 500,
                      lineHeight: { xs: 1.2 },
                    }}
                  >
                    How to Refer & Earn on the SimlessPay
                  </Typography>
                </Stack>
              </Stack>
              {/* <Stack
                direction={"row"}
                sx={{
                  backgroundColor: "#573DB0",
                  borderRadius: "3px",
                  height: { sm: "60px" },
                  width: "90%",
                  padding: "3%",
                  marginBottom: { xs: "30px" },
                  marginTop: { xs: 0 },
                }}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: { xl: 30 },
                    color: "#fff",
                  }}
                >
                  Watch the video{" "}
                </Typography>
                <Button
                  sx={{
                    backgroundColor: "#C7FA56",
                    borderRadius: "2px",
                    height: "100%",
                    ":hover": {
                      backgroundColor: "#C7FA56",
                    },
                    ":active": {
                      backgroundColor: "#C7FA56",
                    },
                  }}
                >
                  <img src={workingIcon} alt="" />{" "}
                </Button>
              </Stack> */}
            </Stack>
          </Item>
        </Grid>
      </Grid>
      <Box>
        <Box
          component="img"
          sx={{
            display: { xs: "none", sm: "block" },
            maxHeight: { sm: 400, xl: 1900, xs: 180, md: 400 },
            position: "absolute",
            left: { lg: "90%", md: "85%", sm: "80%", xs: "83%" },
            top: { lg: "45%", md: "35%", sm: "50%", xs: "65%" },
          }}
          alt="The house from the offer."
          src={imgRight}
        />
      </Box>
    </Box>
  );
};

export default Working;
