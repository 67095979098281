import React from "react";
import { Stack, Typography, Box, Button } from "@mui/material";
import { Grid } from "@material-ui/core";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import TextField from "@mui/material/TextField";
// import IconButton from "@mui/material/IconButton";
// import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
// import "../../../src/assets/img/css/conatct.css";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { FormControl, FormLabel } from "@mui/material";

import { styled } from "@mui/material/styles";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";

const GreyBorder = styled(TextField)`
  & label.Mui-focused {
    color: grey;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: grey;
    }
  }
`;
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: red; */
  // Add your custom styles here
  /* background-color: red; */
`;
const StyledTextArea = styled(TextareaAutosize)`
  width: auto;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 12px 12px 0 12px;
  color: grey;
  border: 1px solid grey;
  box-shadow: 0px 2px 24px red;

  &:hover {
    border-color: blue;
  }

  &:focus {
    border-color: blue;
    box-shadow: 0 0 0 3px blue;
  }

  /* firefox */
  &:focus-visible {
    outline: 0;
  }
`;
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required(" First Name Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Last Name Required"),
  email: Yup.string().email("Invalid email").required(" Email Required"),
  messageText: Yup.string()
    .min(2, "Too Short!")
    .max(1000, "Too Long!")
    .required(" Message Required"),
  phoneNumber: Yup.string()
    .required("Phone Number Required")
    .matches(phoneRegExp, "Phone number is not valid"),
});

const ContactForm = () => {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [RemainigChars, setChars] = useState("");
  // const [total, setTotal] = useState(1000);
  console.log(RemainigChars, "oho");

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <Box
      sx={{
        flexGrow: 1,
        px: { lg: 40, md: 30, sm: 10, xs: 0 },
      }}
      paddingBottom={"200px"}
    >
      <Stack
        direction={"column"}
        textAlign={"center"}
        justifyContent={"center"}
        spacing={2}
        mb={4}
      >
        <Typography
          sx={{
            color: "#7C57FC",
            fontWeight: 600,
            lineHeight: "24px",
            fontFamily: "Montserrat",
          }}
        >
          Contact us
        </Typography>
        <Typography
          variant="h2"
          sx={{
            color: "#101828",
            fontSize: { lg: "36px", md: "25px", sm: "25px", xs: "20px" },
            fontWeight: 700,
            lineHeight: "60px",
            letterSpacing: "-0.96px",
            fontFamily: "IBM Plex Sans",
          }}
        >
          Get in touch
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            color: "#667085",
            lineHeight: "30px",
            fontSize: "18px",
            fontWeight: "400px",
            fontFamily: "Montserrat",
          }}
        >
          We’d love to hear from you. Please fill out this form.
        </Typography>
      </Stack>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          messageText: "",
          phoneNumber: "",
        }}
        validationSchema={SignupSchema}
        onSubmit={(values, actions) => {
          actions.resetForm();
          handleClick();
        }}
      >
        {({ errors, touched, handleSubmit, values }) => (
          <StyledForm onSubmit={handleSubmit}>
            <Stack
              direction={"column"}
              mb={3}
              sx={{ width: { md: "80%", xs: "90%" } }}
            >
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Box>
                  {" "}
                  <Stack
                    direction={"column"}
                    mr={{ xs: 2, sm: 0 }}
                    justifyContent={"space-between"}
                  >
                    <label
                      style={{
                        color: "#667085",
                        lineHeight: "30px",
                        fontSize: "16px",
                        fontWeight: "400px",
                        fontFamily: "Montserrat",
                      }}
                    >
                      First Name
                    </label>
                    <Field
                      name="firstName"
                      as={GreyBorder}
                      inputProps={{
                        style: {
                          padding: "4px 6px",
                        },
                      }}
                    />
                  </Stack>{" "}
                  {errors.firstName && touched.firstName ? (
                    <div style={{ marginLeft: "auto" }}>
                      <Typography
                        sx={{
                          color: "#ff2600",

                          fontSize: "10px",
                          fontWeight: "400px",
                          fontFamily: "Montserrat",
                        }}
                      >
                        {" "}
                        {errors.firstName}
                      </Typography>
                    </div>
                  ) : null}
                </Box>
                <Box>
                  <Stack
                    // mb={3}
                    direction={"column"}
                    justifyContent={"space-between"}
                  >
                    <label
                      style={{
                        color: "#667085",
                        lineHeight: "30px",
                        fontSize: "16px",
                        fontWeight: "400px",
                        fontFamily: "Montserrat",
                      }}
                    >
                      Last Name
                    </label>
                    <Field
                      name="lastName"
                      as={GreyBorder}
                      inputProps={{
                        style: {
                          padding: "4px 6px",
                          innerWidth: "70%",
                          width: "80%",
                        },
                      }}
                    />
                  </Stack>
                  {errors.lastName && touched.lastName ? (
                    <div style={{ marginLeft: "auto" }}>
                      <Typography
                        sx={{
                          color: "#ff2600",
                          lineHeight: "30px",
                          fontSize: "10px",
                          fontWeight: "400px",
                          fontFamily: "Montserrat",
                        }}
                      >
                        {" "}
                        {errors.lastName}
                      </Typography>
                    </div>
                  ) : null}
                </Box>
              </Stack>

              <Stack
                direction={"column"}
                justifyContent={"space-between"}

                // mb={3}
              >
                <label
                  style={{
                    color: "#667085",
                    lineHeight: "30px",
                    fontSize: "16px",
                    fontWeight: "400px",
                    fontFamily: "Montserrat",
                    marginTop: errors.email ? "0px" : "10px",
                  }}
                >
                  Email Address{" "}
                </label>
                <Field
                  name="email"
                  type="email"
                  as={GreyBorder}
                  inputProps={{
                    style: {
                      padding: "4px 6px",
                      width: "100%",
                    },
                  }}
                />
              </Stack>
              {errors.email && touched.email ? (
                <div>
                  <Typography
                    sx={{
                      color: "#ff2600",
                      lineHeight: "30px",
                      fontSize: "10px",
                      fontWeight: "400px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    {" "}
                    {errors.email}
                  </Typography>
                </div>
              ) : null}

              <Stack
                direction={"column"}
                justifyContent={"space-between"}

                // mb={3}
              >
                <label
                  style={{
                    color: "#667085",
                    lineHeight: "30px",
                    fontSize: "16px",
                    fontWeight: "400px",
                    fontFamily: "Montserrat",
                    marginTop: errors.phoneNumber ? "0px" : "10px",
                  }}
                >
                  Phone Number{" "}
                </label>
                <Field
                  name="phoneNumber"
                  type="tel"
                  as={GreyBorder}
                  inputProps={{
                    style: {
                      padding: "4px 6px",
                      width: "100%",
                    },
                  }}
                />
              </Stack>
              {errors.phoneNumber && touched.phoneNumber ? (
                <div>
                  <Typography
                    sx={{
                      color: "#ff2600",
                      lineHeight: "30px",
                      fontSize: "10px",
                      fontWeight: "400px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    {" "}
                    {errors.phoneNumber}
                  </Typography>
                </div>
              ) : null}
              <Stack direction={"column"} justifyContent={"space-between"}>
                <label
                  style={{
                    color: "#667085",
                    lineHeight: "30px",
                    fontSize: "18px",
                    fontWeight: "400px",
                    fontFamily: "Montserrat",
                    marginTop: errors.messageText ? "0px" : "10px",
                  }}
                >
                  Message{" "}
                </label>

                <Field
                  className="custom-textarea"
                  as="textarea"
                  name="messageText"
                  id="messageText"
                  cols={30}
                  rows={7}
                  inputProps={{
                    style: {
                      borderColor: "grey",
                    },
                  }}
                />
              </Stack>
              {errors.messageText && touched.messageText ? (
                <div style={{ marginBottom: "6px" }}>
                  <Typography
                    sx={{
                      color: "#ff2600",
                      lineHeight: "30px",
                      fontSize: "10px",
                      fontWeight: "400px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    {errors.messageText}
                  </Typography>
                </div>
              ) : null}
              <Stack>
                <button
                  onClick={() => console.log(values, "daat")}
                  style={{
                    backgroundColor: "#7C57FC",
                    padding: "8px 16px",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    marginTop: "20px",
                    color: "#fff",
                  }}
                  type="submit"
                >
                  Submit
                </button>
              </Stack>
              <Snackbar
                open={open}
                autoHideDuration={1000}
                onClose={handleClose}
                message="Form Submitted"
                action={action}
              />
            </Stack>
          </StyledForm>
        )}
      </Formik>
    </Box>
  );
};

export default ContactForm;
