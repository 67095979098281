import React from "react";
import Hero from "../components/ContactUsPage/Hero";
import DrwaerComponent from "../components/DrawerComponent";
import ContactForm from "../components/ContactUsPage/ContactForm";
import { Box } from "@mui/material";
import Maps from "../components/ContactUsPage/Maps";
import Footer from "../components/Footer";

const ContactUs = () => {
  return (
    <div>
      <DrwaerComponent />
      <Hero />

      <Maps />

      <ContactForm />
      <Footer />
    </div>
  );
};

export default ContactUs;
