import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import abouBackground from "../../assets/img/AboutImg/Subtract.png";

const OurStory = () => {
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "#EEFECC",

          position: "relative",
          py: { xl: 10, xs: 7 },
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            px: { md: 20, sm: 10, xs: 4 },
            py: { sm: 2, xs: 0 },
            zIndex: 100,
          }}
        >
          <Stack direction={"column"} spacing={1} mb={5}>
            <Typography
              sx={{ color: "#7C57FC", fontWeight: 600, lineHeight: "24px" }}
            >
              Our story
            </Typography>
            <Typography
              variant="h2"
              sx={{
                color: "#101828",
                fontSize: { sm: "36px", xs: "20px" },
                fontWeight: 700,
                lineHeight: { sm: "44px", xs: "30px" },
                letterSpacing: "-0.72px",
              }}
            >
              We’re just getting started
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                color: "#667085",
                lineHeight: { sm: "30px", xs: "20px" },
                fontSize: "18px",
                fontWeight: "400px",
                fontFamily: "Montserrat",
                fontSize: { sm: "18px", xs: "16px" },
              }}
            >
              Download our app for free to send money online in minutes. Track
              your payments and view your transfer history from anywhere.
            </Typography>
          </Stack>
          {/* <Grid container spacing={5}>
            <Grid item sm={6} xs={12}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#667085",
                  lineHeight: { sm: "30px", xs: "20px" },
                  fontSize: "18px",
                  fontWeight: "400px",
                  fontFamily: "Montserrat",
                  fontSize: { sm: "18px", xs: "16px" },
                }}
              >
                Lorem ipsum dolor sit amet consectetur. Massa vitae malesuada
                egestas massa duis. Nisl at sed feugiat ut eu leo eu. Nam duis
                morbi tempus cursus accumsan fermentum. Neque convallis
                venenatis lacus neque. Et sagittis tellus fringilla nam nulla
                leo. Sapien quis fermentum sem id leo pellentesque velit
                pulvinar ante. Nulla et massa venenatis etiam eget. Et viverra
                habitasse cras feugiat nunc arcu montes. Tellus eleifend nisl
                adipiscing nec.
              </Typography>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Stack>
                <List
                  sx={{
                    listStyleType: "disc",
                    listStylePosition: "inside",
                    fontFamily: "Montserrat",
                  }}
                >
                  <ListItem sx={{ display: "list-item", color: "#667085" }}>
                    Mi tincidunt elit, id quisque ligula ac diam, amet.
                  </ListItem>
                  <ListItem sx={{ display: "list-item", color: "#667085" }}>
                    Vel etiam suspendisse morbi eleifend faucibus eget
                    vestibulum.
                  </ListItem>
                  <ListItem sx={{ display: "list-item", color: "#667085" }}>
                    Tellus aliquam enim urna, etiam. Mauris posuere vulputate.
                  </ListItem>
                </List>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "#667085",

                    fontSize: "18px",
                    fontWeight: "400px",
                    fontFamily: "Montserrat",
                    fontSize: { sm: "18px", xs: "16px" },
                    lineHeight: { sm: "30px", xs: "20px" },
                  }}
                >
                  Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim
                  mauris id. Non pellentesque congue eget consectetur turpis.
                  Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt
                  aenean tempus.
                </Typography>
              </Stack>
            </Grid>
          </Grid> */}
        </Box>
      </Box>
    </Box>
  );
};

export default OurStory;
