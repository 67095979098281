import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  Link,
  MenuItem,
  Box,
  Button,
} from "@mui/material";
// import { useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import { StyledButton } from "./Button";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";

import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import logo2 from "../assets/img/logo.svg";
import { Stack } from "@mui/material";

const headersData = [
  {
    label: "Home",
    href: "/",
    bgcolor: "blue",
  },
  {
    label: "About",
    href: "/about",
  },
  {
    label: "Privacy Policy",
    href: "/privacy-policy",
  },
  {
    label: "FAQ's",
    href: "/faq",
  },
  {
    label: "Blog",
    href: "/blog",
  },
  {
    label: "Contact",
    href: "/contact",
  },
  {
    label: "Login",
    href: "/",
    // label: (
    //   <Box>
    //     <Button
    //       variant="contained"
    //       sx={{
    //         borderRadius: "8px",
    //         backgroundColor: "#7C57FC",
    //         borderBottom: "none",
    //       }}
    //     >
    //       Login
    //     </Button>
    //   </Box>
    // ),
    href: "/",
  },
];

const useStyles = makeStyles(() => ({
  logo: {
    fontFamily: "Work Sans, sans-serif",
    fontWeight: 600,
    color: "#FFFEFE",
    textAlign: "left",
  },
  toolbar: {
    padding: 0,
    minHeight: "100px",
  },
  header: {
    height: "80px",
  },
  drawerContainer: {
    paddingTop: "90px",
  },
}));

export default function DrwaerComponent() {
  let location = useLocation();
  const pathName = location.pathname;
  const navigate = useNavigate();
  const { header, logo, menuButton, toolbar, drawerContainer } = useStyles();
  const [colorVal, setColor] = useState(false);

  const checkClick = () => {
    console.log("checkClick");
    //  useLocation().pathname !== "/" ? setColor(true) : setColor(false);
  };

  const colorlocation = useLocation();
  let textColor = colorlocation.pathname !== "/" ? "#7C57FC" : "white";

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar
        className={toolbar}
        sx={{ justifyContent: "space-between", zIndex: 90 }}
      >
        <Link href="/" style={{ color: "#fff", textDecoration: "none" }}>
          <Stack
            spacing={1}
            direction={"row"}
            sx={{
              justifyContent: "center",
              alignSelf: "center",
              alignItems: "center",
              // backgroundColor: "green",
              minHeight: { xl: "90px", lg: "100px", md: "100px" },
            }}
          >
            {" "}
            {/* p1 */}
            <Box
              component="img"
              sx={{
                height: { sm: 25, xs: 15 },
                width: 25,
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
              }}
              alt="The house from the offer."
              src={logo2}
            />
            <Link to="/" sx={{ textDecoration: "none" }}>
              <Typography variant="h6" sx={{ color: textColor }}>
                SimlessPay
              </Typography>
            </Link>
          </Stack>
        </Link>

        <div>{getMenuButtons()}</div>
      </Toolbar>
    );
  };

  const handleDrawerClose = () =>
    setState((prevState) => ({ ...prevState, drawerOpen: !state }));

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: !state }));

    return (
      <Toolbar>
        <IconButton
          {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer} sx={{ paddingTop: "200px" }}>
            {getDrawerChoices()}
          </div>
        </Drawer>

        <Link to="/" sx={{ textDecoration: "none" }}>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box
              component="img"
              sx={{
                height: 30,
                width: 50,
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
              }}
              alt="The house from the offer."
              src={logo2}
            />
            <Typography variant="h6" sx={{ color: textColor }}>
              SimlessPay
            </Typography>
          </Stack>
        </Link>
        {/* <Link to="/" sx={{ color: textColor, textDecoration: "none" }}>
            <Typography
              variant="text"
              sx={{
                fontsize: "20px",
                fontWeight: 700,
                color: { textColor },
              }}
            >
              SimlessPay
            </Typography>
          </Link> */}
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Box sx={{ textDecoration: "none" }}>
          <Link
            sx={{ textDecoration: "none", color: "grey" }}
            to={href}
            onClick={() => {
              navigate(href);
              handleDrawerClose();
            }}
          >
            <MenuItem>{label}</MenuItem>
          </Link>
        </Box>
      );
    });
  };

  const femmecubatorLogo = (
    <Typography
      variant="h6"
      component="h1"
      className={logo}
      sx={{ color: useLocation().pathname !== "/" ? "#7C57FC" : "red" }}
    >
      <Button sx={{ background: "red" }}>SimlessPay</Button>
    </Typography>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return label !== "Login" ? (
        <NavLink
          sx={{ marginLeft: "10px", fontSize: "16px", fontWeight: "bold" }}
          {...{
            key: label,
            color: "inherit",
            to: href,
            component: RouterLink,
            className: menuButton,
          }}
          style={({ isActive, isPending }) => {
            return {
              fontWeight: isActive ? "bold" : "",
              borderBottom:
                isActive && label !== "Login"
                  ? `2px solid ${pathName === "/" ? "#fff" : "#7C57FC"}`
                  : "",
              marginLeft: "30px",
              textDecoration: "none",
              fontWeight: "bold",
              color: pathName !== "/" ? "#7C57FC" : "#fff",
              display: "inline-block",
            };
          }}
        >
          {label}
        </NavLink>
      ) : (
        <NavLink
          sx={{ marginLeft: "10px", fontSize: "16px", fontWeight: "bold" }}
          {...{
            key: label,
            color: "inherit",
            to: href,
            component: RouterLink,
            className: menuButton,
          }}
          style={({ isActive, isPending }) => {
            return {
              fontWeight: isActive ? "bold" : "",
              borderBottom:
                isActive && label !== "Login" ? `2px solid textColor` : "",
              marginLeft: "30px",
              textDecoration: "none",
              fontWeight: "bold",

              display: "inline-block",
            };
          }}
        >
          <Button
            variant="contained"
            sx={{ backgroundColor: "#7C57FC", marginLeft: "10px" }}
          >
            Login
          </Button>
        </NavLink>
      );
    });
  };

  return (
    <header sx={{ minHeight: "100px" }}>
      <AppBar
        className={header}
        sx={{
          background: useLocation().pathname !== "/" ? "#fff" : "#03032E",
          color: useLocation().pathname !== "/" ? "#7C57FC" : "#fff",
          boxShadow: "none",
          //backgroundColor: "green",

          padding: { lg: "0 100px", md: "0", sm: "0 10px" },
          zIndex: 9000000000000000000,
        }}
      >
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
    </header>
  );
}
