import React from "react";
import styled from "@emotion/styled";
import { Paper, Box, Stack, Typography, ImageList } from "@mui/material";
import { Grid } from "@mui/material";
import featureLeft from "../assets/img/featureLeftImg.png";
import firstIcon from "../assets/img/Featured icon1.png";
import secondIcon from "../assets/img/Featured icon2.png";
import thirdIcon from "../assets/img/Featured icon3.png";
import featurePersonImg from "../assets/img/FeaturedPerson.png";
import SubtractFeature from "../assets/img/SubtractFeature.png";
import FeatureGroup from "../assets/img/featuregroup.png";
import { ImportsNotUsedAsValues } from "typescript";
// import AspectRatio from "@mui/joy/AspectRatio";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
}));

const BoxStyle = styled(Box)(({ theme }) => ({}));
const img = styled(ImageList)(({ theme }) => ({
  width: "50%",
}));
const gradientStyle = {
  background:
    "linear-gradient(142deg, #C3F25E 0%, #C3F25E 19.55%,#C3F25E 19.55%,#7E5DF5 79.45%,#7E5DF5 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
};

const Features = () => {
  return (
    <div id="contact">
      <Item
        sx={{
          marginBottom: { xl: "3%", lg: 0 },
          paddingTop: { lg: "8%", md: "5%", sm: "5%" },
        }}
      >
        <BoxStyle>
          <Typography
            variant="h4"
            sx={{
              fontSize: {
                xl: 70,
                lg: "45px",
                sm: "25px",
                md: "40px",
                xs: "20px",
                "@media(max-width: 375px)": {
                  fontSize: { xs: "15px" },
                },
              },
              marginLeft: { xs: 2 },
              width: { lg: "800px", md: "600px", xl: "900px", sm: "400px" },
              margin: { lg: "auto", md: "auto", sm: "auto" },
              marginRight: { xl: 110 },
              marginBottom: { xl: 4, sm: "20px", xs: 0 },
              paddingTop: { xs: 5, sm: 0 },
              fontFamily: "IBM Plex Sans",
              fontWeight: 500,
            }}
          >
            Why Choose <span style={gradientStyle}>SimlessPay</span>{" "}
          </Typography>
        </BoxStyle>
        <Grid
          sx={{
            // backgroundColor: "green",
            marginTop: { md: "-10%", sm: "-10%", lg: "-15%", xl: "-8%" },
          }}
          container
          spacing={{ xs: 0, sm: 0, md: 10, lg: 15, xl: 25 }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item xs={2} sm={2} md={2} lg={2}>
            <BoxStyle>
              <Box
                component="img"
                sx={{
                  height: { lg: 630, xl: 630, md: 450, sm: 300, xs: 0 },
                  width: { lg: 270, md: 200, sm: 150, xs: 0 },
                  maxHeight: { xs: 1000, md: 500, lg: 630 },
                  maxWidth: { xs: 1000, md: 200, lg: 481 },
                }}
                alt="The house from the offer."
                src={featureLeft}
              />
            </BoxStyle>
          </Grid>
          <Grid item xs={12} sm={6} lg={5} md={6}>
            <BoxStyle
              sx={{
                padding: { xs: "0 10px", sm: 0 },
                "@media (max-width: 900px)": {
                  marginTop: "5rem",
                },
                "@media (max-width: 480px)": {
                  marginTop: "1rem",
                },
              }}
            >
              <Stack direction={"column"}>
                <Stack direction={"row"} sx={{ marginBottom: "2rem" }}>
                  <Stack sx={{ marginRight: "1rem" }}>
                    <Box
                      component="img"
                      sx={{
                        height: { xs: 30, sm: 40 },
                        width: { xs: 30, sm: 40 },
                        maxHeight: { xs: 233, md: 167 },
                        maxWidth: { xs: 350, md: 250 },
                      }}
                      alt="The house from the offer."
                      src={firstIcon}
                    />
                  </Stack>
                  <Stack direction={"column"}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        marginBottom: { xs: "0.5rem" },
                        fontWeight: { xs: "bold" },
                        fontSize: { xl: 40 },
                        fontFamily: "IBM Plex Sans",
                      }}
                    >
                      Safe
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: { xl: 25 },
                        fontFamily: "Montserrat",
                        color: "var(--gray-500, #667085)",
                      }}
                    >
                      100% secure & regulated by FCA
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              {/* <Stack direction={"column"}>
                <Stack direction={"row"} sx={{ marginBottom: "2rem" }}>
                  <Stack sx={{ marginRight: "1rem" }}>
                    <Box
                      component="img"
                      sx={{
                        height: { xs: 30, sm: 40 },
                        width: { xs: 30, sm: 40 },
                        maxHeight: { xs: 233, md: 167 },
                        maxWidth: { xs: 350, md: 250 },
                      }}
                      alt="The house from the offer."
                      src={secondIcon}
                    />
                  </Stack>
                  <Stack direction={"column"}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        marginBottom: { xs: "0.5rem" },
                        fontWeight: { xs: "bold" },
                        fontSize: { xl: 40 },
                        fontFamily: "IBM Plex Sans",
                      }}
                    >
                      Instant Transfer{" "}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: { xl: 25 },
                        fontFamily: "Montserrat",
                        color: "var(--gray-500, #667085)",
                      }}
                    >
                      Transfer is done instantly
                    </Typography>
                  </Stack>
                </Stack>
              </Stack> */}
              <Stack direction={"column"}>
                <Stack direction={"row"} sx={{ marginBottom: "2rem" }}>
                  <Stack sx={{ marginRight: "1rem" }}>
                    <Box
                      component="img"
                      sx={{
                        height: { xs: 30, sm: 40 },
                        width: { xs: 30, sm: 40 },
                        maxHeight: { xs: 233, md: 167 },
                        maxWidth: { xs: 350, md: 250 },
                      }}
                      alt="The house from the offer."
                      src={thirdIcon}
                    />
                  </Stack>

                  <Stack direction={"column"}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        marginBottom: { xs: "0.5rem" },
                        fontWeight: { xs: "bold" },
                        fontSize: { xl: 40 },
                        fontFamily: "IBM Plex Sans",
                      }}
                    >
                      Highly Competitive Exchange Rate
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: { xl: 25 },
                        fontFamily: "Montserrat",
                        color: "var(--gray-500, #667085)",
                      }}
                    >
                      Make pounds with naira transaction's at best exchange
                      rate's
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </BoxStyle>
          </Grid>

          <Grid
            item
            padding={0}
            xs={12}
            sm={4}
            md={4}
            spacing={0}
            lg={5}
            paddingLeft={0}
            sx={{
              "@media (max-width: 900px)": {
                // backgroundColor: "red",
                paddingTop: "0",
              },
            }}
          >
            <Stack
              direction={"row"}
              sx={{
                "@media (max-width: 900px)": {
                  padding: 0,
                  justifyContent: "center",
                },
              }}
            >
              <BoxStyle
                sx={{
                  zIndex: 100,
                }}
              >
                <Box sx={{ marginRight: "-100px", zIndex: "tooltip" }}>
                  <Box
                    component="img"
                    sx={{
                      height: { lg: 400, md: 350, sm: 250, xl: 600, xs: 250 },
                      width: { lg: 350, md: 250, sm: 170, xl: 400, xs: 200 },
                      maxHeight: { xs: 1233, md: 1007 },
                      maxWidth: { xs: 3150, md: 1000 },
                      "@media(max-width: 425px)": {
                        display: "none",
                      },
                    }}
                    alt="The house from the offer."
                    src={featurePersonImg}
                  />
                </Box>
              </BoxStyle>
              <BoxStyle
                sx={{
                  zIndex: 50,
                }}
              >
                <BoxStyle>
                  <Stack
                    direction={"column"}
                    spacing={{ xs: 10, sm: 13, md: 20, lg: 20, xl: 40 }}
                  >
                    <Box
                      component="img"
                      sx={{
                        height: { xl: 200, lg: 200, md: 200, sm: 120, xs: 100 },
                        width: { xl: 200, lg: 160, md: 130, sm: 150, xs: 120 },
                        maxHeight: { xs: 233, md: 167 },
                        maxWidth: { xs: 350, md: 250 },
                        // backgroundColor: "green",
                        "@media(max-width: 425px)": {
                          display: "none",
                        },
                      }}
                      alt="The house from the offer."
                      src={SubtractFeature}
                    />
                    <Box
                      component="img"
                      sx={{
                        height: { xl: 70, lg: 50, md: 50, sm: 30 },
                        width: { xl: 200, lg: 150, md: 125, sm: 150 },
                        maxHeight: { xs: 1000, md: 1100 },
                        maxWidth: { xs: 1000, md: 1000 },
                        marginBottom: { lg: 100 },
                        // backgroundColor: "red",
                        "@media(max-width: 425px)": {
                          display: "none",
                        },
                      }}
                      alt="The house from the offer."
                      src={FeatureGroup}
                    />
                  </Stack>
                </BoxStyle>
              </BoxStyle>
            </Stack>
          </Grid>
        </Grid>
      </Item>
    </div>
  );
};

export default Features;
