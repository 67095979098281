import React from "react";
import Hero from "../components/HowItWorkss/Hero";
import DrwaerComponent from "../components/DrawerComponent";
import Footer from "../components/Footer";
import { BrowserRouter } from "react-router-dom";

const HowItWorks = () => {
  return (
    <div>
      <DrwaerComponent />

      <Hero />
      <Footer />
    </div>
  );
};

export default HowItWorks;
