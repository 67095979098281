import React from "react";
import { useLocation } from "react-router-dom";
import { Box, Typography, Stack, Grid } from "@mui/material";

const ProductPage = () => {
  const location = useLocation();
  return (
    <div>
      <div>
        {" "}
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <Stack direction={"column"} spacing={10}>
              <Box>
                <Box
                  component="img"
                  sx={{
                    height: "100%",
                    width: "100%",
                    maxHeight: { xs: "100%" },
                    maxWidth: { xs: "100%" },
                  }}
                  alt="The house from the offer."
                  src={location.state.imgPath}
                />
                <Typography
                  sx={{
                    color: "#7C57FC",
                    fontWeight: 600,
                    lineHeight: "24px",
                  }}
                >
                  {location.state.name}
                </Typography>

                <Typography
                  sx={{
                    color: "#101828",
                    fontWeight: 600,

                    fontSize: "20px",
                  }}
                >
                  {location.state.post}
                </Typography>

                <Typography
                  variant="subtitle2"
                  maxWidth={"768px"}
                  sx={{
                    color: "#667085",
                    lineHeight: "24px",
                    fontSize: "15px",
                    fontWeight: "400px",
                    fontFamily: "Montserrat",

                    display: "flex",
                  }}
                >
                  {location.state.description}
                </Typography>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ProductPage;
