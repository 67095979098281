import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import DrwaerComponent from "../DrawerComponent";
import Navbar from "../Navbar";
import { Typography } from "@mui/material";
import HeroImg from "../../assets/img/AboutImg/HeroImgAbout.png";

const Hero = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        px: { md: 20, sm: 20, xs: 5 },
        py: 2,
        paddingTop: "10rem",
      }}
    >
      <Typography
        sx={{ color: "#7C57FC", fontWeight: 600, lineHeight: "24px" }}
      >
        Contact us
      </Typography>
      <Grid container spacing={{ sm: 2, xs: 0.5 }} mb={10}>
        <Grid item md={8} sm={12}>
          <Typography
            variant="h2"
            sx={{
              color: "#101828",
              fontSize: { lg: "36px", md: "25px", sm: "25px", xs: "20px" },
              fontWeight: 700,
              lineHeight: "60px",
              letterSpacing: "-0.96px",
              fontFamily: "IBM Plex Sans",
            }}
          >
            We’d love to hear from you
          </Typography>
        </Grid>
        <Grid item md={4} sm={12}>
          <Typography
            variant="subtitle2"
            sx={{
              color: "#667085",
              lineHeight: "30px",
              fontSize: "18px",
              fontWeight: "400px",
              fontFamily: "Montserrat",
            }}
          >
            Our friendly team is always here to chat.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Hero;
