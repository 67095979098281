import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import DrwaerComponent from "../DrawerComponent";
import Navbar from "../Navbar";
import { Typography } from "@mui/material";
import HeroImg from "../../assets/img/AboutImg/HeroImgAbout.png";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Hero = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        px: { md: 20, sm: 10, xs: 4 },
        pt: { lg: 10, xs: 10 },
        mt: 6,
      }}
    >
      <Typography
        sx={{
          color: "#7C57FC",
          fontWeight: 600,
          lineHeight: "24px",
          fontFamily: "Montserrat",
        }}
      >
        Nice to meet you
      </Typography>
      <Grid container spacing={2} mb={{ sm: 10, xs: 5 }}>
        <Grid item xs={12} sm={8}>
          <Typography
            variant="h2"
            sx={{
              color: "#101828",
              fontSize: { sm: "48px", xs: "20px" },
              fontWeight: 700,
              lineHeight: { sm: "60px", xs: "30px" },
              letterSpacing: "-0.96px",
              fontFamily: "IBM Plex Sans ",
            }}
          >
            Everything You Need To Know About Sending Money With SimlessPay
          </Typography>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography
            variant="subtitle2"
            sx={{
              color: "#667085",
              lineHeight: { sm: "30px", xs: "20px" },
              fontSize: { sm: "18px", xs: "10px" },
              fontWeight: "400px",
              fontFamily: "Montserrat",
              fontSize: { sm: "18px", xs: "16px" },
            }}
          >
            Simlesspay was launched in 2023 with the vision of making
            international money transfers cheap, fair, & simple. Today, our
            multi-currency account helps millions of people & businesses manage
            their money across the world. Here's how we got here.
          </Typography>
        </Grid>
      </Grid>
      {/* <Box mb={{ sm: 10, xs: 3 }}>
        <Box
          component="img"
          sx={{
            width: "100%",
            height: "100%",
          }}
          alt="The house from the offer."
          src={HeroImg}
        />
      </Box> */}
    </Box>
  );
};

export default Hero;
