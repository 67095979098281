import React from "react";
import Box from "@material-ui/core/Box/Box";
import Hero from "../components/Aboutpage/Hero";
import OurStory from "../components/Aboutpage/OurStory";
import OurTeam from "../components/Aboutpage/OurTeam";
import HowWork from "../components/Aboutpage/HowWork";
import CalltoAction from "../components/CalltoAction";
import LatestNews from "../components/Aboutpage/LatestNews";
import DrwaerComponent from "../components/DrawerComponent";
import DummyTeam from "../components/Aboutpage/DummyTeam";
import Footer from "../components/Footer";
import { BrowserRouter } from "react-router-dom";
const About = () => {
  return (
    <Box>
      <DrwaerComponent />

      <Hero />
      <OurStory />
      {/* <OurTeam /> */}
      {/* <DummyTeam /> */}
      <HowWork />
      <CalltoAction />
      {/* <LatestNews /> */}
      <Footer />
    </Box>
  );
};

export default About;
