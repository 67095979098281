import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Stack,
  Button,
  Box,
} from "@mui/material";
import { StyledButton } from "./Button";
import logo from "../assets/img/logo.svg";
import { Link, NavLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import styled from "styled-components";
import { red } from "@mui/material/colors";

const MyThemeComponent = styled(Typography)(({ theme }) => ({
  backgroundColor: "transparent",
  // color: theme.palette.text.secondary,
}));

const logoTextColor = {
  color: "#344054",
};

const Navbar = () => {
  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "transparent", marginBottom: "5%" }}
    >
      <Toolbar>
        <Grid container>
          {/* Adjust the spacing value as needed */}
          <Grid item xs={4}>
            <Stack direction="row" alignItems="center">
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="logo"
              >
                <img src={logo} alt="Logo" />
              </IconButton>
              <Typography variant="h6" component="div">
                SimlessPay
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={8}
            sx={{ alignSelf: "center", textDecoration: "none" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <NavLink to="/" style={{ textDecoration: "inherit" }}>
                  <MyThemeComponent variant="subtitle1" sx={{ color: "#fff" }}>
                    About
                  </MyThemeComponent>
                </NavLink>
              </Box>
              <Box>
                <NavLink to="/" style={{ textDecoration: "inherit" }}>
                  <MyThemeComponent variant="subtitle1" sx={{ color: "#fff" }}>
                    Features
                  </MyThemeComponent>
                </NavLink>
              </Box>
              <Box>
                <NavLink to="/" style={{ textDecoration: "inherit" }}>
                  <MyThemeComponent variant="subtitle1" sx={{ color: "#fff" }}>
                    How It Works
                  </MyThemeComponent>
                </NavLink>
              </Box>

              <Box>
                <NavLink to="/blog" style={{ textDecoration: "inherit" }}>
                  <MyThemeComponent variant="subtitle1" sx={{ color: "#fff" }}>
                    FAQ
                  </MyThemeComponent>
                </NavLink>
              </Box>
              <Box>
                <NavLink to="/faq" style={{ textDecoration: "inherit" }}>
                  <MyThemeComponent variant="subtitle1" sx={{ color: "#fff" }}>
                    Blogs
                  </MyThemeComponent>
                </NavLink>
              </Box>
              <Box>
                <NavLink to="/faq" style={{ textDecoration: "inherit" }}>
                  <MyThemeComponent variant="subtitle1" sx={{ color: "#fff" }}>
                    Contact
                  </MyThemeComponent>
                </NavLink>
              </Box>

              <Box>
                <Button variant="contained">Login</Button>
                {/* <StyledButton variant="contained">Login</StyledButton> */}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
