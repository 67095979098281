import { Button } from "@mui/material";
import React from "react";
import styled from "styled-components";
// export const StyledButton = styled(Button)({});
export const StyledButton = styled(Button)(({ theme }) => ({
  display: "flex",
  width: "80px",
  height: "40px",
  padding: "10px",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "8px",
}));
