import React from "react";
import Hero from "../components/BlogPage/Hero";
import RecentBlogs from "../components/BlogPage/RecentBlogs";
import AllBlogs from "../components/BlogPage/AllBlogs";

import DrwaerComponent from "../components/DrawerComponent";
import Footer from "../components/Footer";
import BlogAnimate from "../components/BlogPage/BlogAnimate";
import Box from "@mui/material/Box";

const Blog = () => {
  return (
    <Box>
      {/* <DrwaerComponent /> */}

      {/* <Hero /> */}
      <BlogAnimate />

      <Footer />

      {/* <RecentBlogs />
      <AllBlogs /> */}
    </Box>
  );
};

export default Blog;
