import React from "react";
import Hero from "../components/BlogPage/Hero";
import RecentBlogs from "../components/BlogPage/RecentBlogs";
import AllBlogs from "../components/BlogPage/AllBlogs";

import DrwaerComponent from "../components/DrawerComponent";
import Footer from "../components/Footer";
import BlogAnimate from "../components/BlogPage/BlogAnimate";
import Box from "@mui/material/Box";
import BlogDetail from "../components/BlogPage/BlogDetails";

const BlogDetails = () => {
    return (
        <Box>
            <DrwaerComponent />

            {/* <Hero /> */}
            <BlogDetail />

            <Footer />

            {/* <RecentBlogs />
      <AllBlogs /> */}
        </Box>
    );
};

export default BlogDetails;
