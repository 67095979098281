import React from "react";
import { Grid, Box, Stack, Typography, Icon, Button } from "@mui/material";
import styled from "@emotion/styled";
import { FaQuoteLeft, FaArrowRight, FaArrowLeft } from "react-icons/fa";
import Img from "../assets/img/reviewImg.png";
import ReviewArrow from "../assets/img/reviewsArrow.png";
import ReviewArrowTop from "../assets/img/reviewsArrowTop.png";
import ReviewStar from "../assets/img/reviewStar.png";
import ArrowSmall from "../assets/img/reviewArrowSmall.png";
import RightArrow from "../assets/img/rightArrow.png";
import Slider from "react-slick";
import { useRef } from "react";
import "../../src/slik.css";
import { ReviewsArray } from "../Reviews";
const Item = styled(Box)(({ theme }) => ({}));

const Reviews = () => {
  const sliderRef = useRef<any>(null);
  const gotoNext = () => {
    sliderRef.current.slickNext();
  };
  const gotoPrev = () => {
    sliderRef.current.slickPrev();
  };

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: true,
    arrows: false,
    rows: 1,

    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          speed: 500,

          rows: 1,
        },
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          speed: 500,

          rows: 1,
        },
      },
    ],
  };
  return (
    <Box
      sx={{ px: { lg: 5, md: 5, sm: 5, xs: 2 }, py: { md: 4, sm: 4, xs: 2 } }}
    >
      <Slider ref={sliderRef} {...settings}>
        {ReviewsArray.map((item) => (
          <Box
            sx={{
              marginBottom: { xs: 5 },
            }}
          >
            <Grid
              container
              // rowSpacing={20}
              // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              spacing={{ xs: 0, sm: 1, md: 10, lg: 2, xl: 20 }}
              // sx={{
              //   "@media (max-width: 900px)": {
              //     display: "flex",
              //     flexDirection: "column",
              //     justifyContent: "center",
              //     alignItems: "center",
              //   },
              // }}
            >
              <Grid
                item
                xs={12}
                sm={6}
                lg={6}
                md={6}
                xl={5}
                sx={{
                  marginX: { sm: 0, xs: 0 },
                  // backgroundColor: "red",
                  width: "100%",
                }}
              >
                <Stack
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Item>
                    <Stack direction={"column"}>
                      <Box
                        sx={{
                          position: "absolute",
                          left: { xl: "290px", lg: "100px", md: "100px" },

                          marginTop: "10%",
                          zIndex: -10,
                          "@media (max-width: 900px)": {
                            left: "0px",
                          },
                        }}
                      >
                        <Box
                          component="img"
                          sx={{
                            height: { xs: 50, sm: 233 },
                            width: { sm: 500, xs: 150 },
                            maxHeight: { xs: 233, md: 167 },
                            maxWidth: { xs: 350, md: 250 },
                          }}
                          alt="The house from the offer."
                          src={ReviewArrowTop}
                        />
                      </Box>
                      <Box
                        mt={20}
                        sx={{
                          "@media (max-width: 900px)": {
                            display: "none",
                          },
                        }}
                      >
                        <Box
                          component="img"
                          sx={{
                            maxHeight: { xs: 200, md: 280, lg: 250, xl: 350 },
                            maxWidth: { xs: 300, md: 200, lg: 300, xl: 400 },
                          }}
                          alt="The house from the offer."
                          src={ReviewArrow}
                        />
                      </Box>
                    </Stack>
                  </Item>
                  <Item>
                    <Stack
                      justifyContent={"center"}
                      alignItems={"center"}
                      direction={"column"}
                      width={{ xs: "100%" }}
                      spacing={{ lg: 8, xl: 3 }}
                      // sx={{
                      //   marginLeft: "-15%",
                      //   "@media (max-width: 900px)": {
                      //     marginLeft: 0,
                      //   },
                      // }}
                      sx={{
                        marginLeft: {
                          sm: 0,
                          xs: 0,
                          md: "-15%",
                          lg: "-15%",
                          xl: "-15%",
                        },
                        md: {
                          marginLeft: "0",
                        },
                      }}
                    >
                      <Typography
                        variant="h4"
                        sx={{
                          "@media (min-width: 2560px)": {
                            fontSize: {
                              sm: "2.125rem",
                              xs: "16px",
                              xl: "90px",
                            },

                            justifyContent: "center",
                            alignItems: "center",
                            alignSelf: "center",
                          },
                          fontSize: { xs: "25px", md: "30px" },
                          marginBottom: { xs: 2, sm: 0 },
                          fontFamily: "IBM Plex Sans",
                          fontWeight: 700,
                          mb: { sm: 3 },
                        }}
                      >
                        What Client Says
                      </Typography>
                      <Box
                        component="img"
                        sx={{
                          borderRadius: 5,
                          height: { sm: 150, xs: 100 },
                          width: { sm: 200, xs: 100 },
                          minHeight: {
                            md: 230,
                            lg: 300,
                            xl: 500,
                            sm: 200,
                          },
                          minWidth: {
                            md: 230,
                            lg: 300,
                            xl: 500,
                            sm: 200,
                          },
                        }}
                        alt="The house from the offer."
                        src={item.imgPath}
                      />
                    </Stack>
                  </Item>
                </Stack>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={7}
                sx={{
                  marginTop: "6%",
                  "@media (max-width: 900px)": {
                    justifyContent: "center",
                    alignItems: "center",
                  },
                }}
              >
                <Item
                  sx={
                    {
                      // backgroundColor: "gray",
                      // width: { sm: "300px", xl: "1000px", md: "600px" },
                    }
                  }
                >
                  <Stack
                    direction={"column"}
                    spacing={3}
                    sx={{
                      "@media (max-width: 900px)": {
                        justifyContent: "center",
                      },
                    }}
                  >
                    <Stack
                      direction={"row"}
                      justifyContent={{ xs: "center" }}
                      sx={{
                        "@media (max-width: 2560px)": {
                          height: "12%",
                        },
                      }}
                    >
                      <Box
                        component="img"
                        sx={{
                          minHeight: { xl: 80 },
                          minWidth: { xl: 80 },
                        }}
                        alt="The house from the offer."
                        src={ReviewStar}
                      />
                      <Box
                        component="img"
                        sx={{
                          minHeight: { xl: 80 },
                          minWidth: { xl: 80 },
                        }}
                        alt="The house from the offer."
                        src={ReviewStar}
                      />
                      <Box
                        component="img"
                        sx={{
                          minHeight: { xl: 80 },
                          minWidth: { xl: 80 },
                        }}
                        alt="The house from the offer."
                        src={ReviewStar}
                      />
                      <Box
                        component="img"
                        sx={{
                          minHeight: { xl: 80 },
                          minWidth: { xl: 80 },
                        }}
                        alt="The house from the offer."
                        src={ReviewStar}
                      />
                      <Box
                        component="img"
                        sx={{
                          minHeight: { xl: 80 },
                          minWidth: { xl: 80 },
                        }}
                        alt="The house from the offer."
                        src={ReviewStar}
                      />
                    </Stack>
                    <Typography
                      variant="h6"
                      sx={{
                        lineHeight: { lg: 3, xl: 1.5, xs: 2 },
                        letterSpacing: { sm: "0.4", xs: 0.2 },

                        color: "black",
                        fontSize: { xl: 50, sm: "16px", xs: "16px", lg: 20 },
                        fontFamily: "Montserrat",
                        fontWeight: 200,
                      }}
                    >
                      {item.review}
                    </Typography>
                    <Stack
                      sx={{
                        fontSize: { sm: "16px", xs: "12px" },

                        "@media (min-width: 2560px)": {
                          fontSize: { sm: "30px" },
                        },
                      }}
                    >
                      <span>{item.name}</span>
                    </Stack>
                  </Stack>
                </Item>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Slider>{" "}
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <Stack
          direction={"row"}
          spacing={{ md: 23, sm: 11, xs: 5 }}
          justifyContent={"space-between"}
        >
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Button
              variant="text"
              className="prevBtn"
              onClick={gotoPrev}
              sx={{
                minWidth: { sm: "64px", xs: "30px" },
                marginRight: "5px",

                width: { sm: 10, xs: 1 },
                backgroundColor: "var(--green-yellow-tint-60, #E9FDBB)",
                ":hover": {
                  backgroundColor: "var(--green-yellow-tint-60, #E9FDBB)",
                },
                ":active": {
                  backgroundColor: "var(--green-yellow-tint-60, #E9FDBB)",
                },
              }}
            >
              <FaArrowLeft color="grey" aria-label="Previous testimonial" />
            </Button>
            <Typography
              ml={{ sm: 2, xs: 0 }}
              sx={{ fontSize: { xs: "12px", sm: "16px" } }}
            >
              {" "}
              Previous
            </Typography>
          </Stack>

          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography mr={2} sx={{ fontSize: { xs: "12px", sm: "16px" } }}>
              Next
            </Typography>
            <Button
              variant="text"
              className="nextBtn"
              onClick={() => gotoNext()}
              sx={{
                minWidth: { sm: "64px", xs: "30px" },

                width: { sm: 10, xs: 1 },
                backgroundColor: "var(--green-yellow-tint-60, #E9FDBB)",
                ":hover": {
                  backgroundColor: "var(--green-yellow-tint-60, #E9FDBB)",
                },
                ":active": {
                  backgroundColor: "var(--green-yellow-tint-60, #E9FDBB)",
                },
              }}
            >
              <FaArrowRight color="grey" aria-label="Next testimonial" />
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default Reviews;
