import React from "react";
import { Grid, Box, Typography, Stack } from "@mui/material";
import Appstore from "../assets/img/Mobile app store badge.png";
import googleImg from "../assets/img/google.png";
import Content from "../assets/img/image 1.png";
import Iconwrap from "../assets/img/Icon wrap.png";
import { styled } from "@mui/material/styles";

const Root = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down("md")]: {
    width: 100,
  },
  [theme.breakpoints.up("md")]: {
    width: 50,
  },
  [theme.breakpoints.up("lg")]: {
    width: 100,
  },
}));

const ContactUs = () => {
  const showMap = () => {
    window.open("https://maps.google.com?q=" + 53.49202 + "," + -2.21367);
  };
  return (
    <div>
      <Box
        sx={{
          margin: " 0 10%",
        }}
      >
        <Grid
          container
          spacing={{ sm: 10, xs: 10 }}
          marginBottom={{ xs: 0, sm: 10 }}
          sx={{
            width: "100%",
            display: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} sm={6} md={6} lg={6} mb={{ xs: 3, sm: 1 }}>
            <Stack direction={"column"} spacing={{ lg: 1 }}>
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: { xs: 700, xl: 900 },
                  fontSize: { xl: 80 },
                  letterSpacing: { xl: 2 },
                  color: "var(--primary-700, #6941C6)",
                  fontFamily: "Montserrat",
                }}
              >
                Contact Us
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: { xs: 700, xl: 900 },
                  fontSize: { xl: 30, lg: "26px" },
                  letterSpacing: { xl: 2 },
                  color: "var(--gray-900, #101828)",
                  fontFamily: "IBM Plex Sans",
                }}
              >
                Chat to our friendly team
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: { xl: 20, lg: "20px", xs: "15px" },
                  letterSpacing: { xl: 2 },
                  color: "var(--gray-500, #667085)",
                  fontFamily: " Montserrat",
                  fontWeight: 300,
                }}
              >
                We would love to hear from you. Please stay in touch !
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            lg={6}
            md={6}
            spacing={2}
            mt={4}
            sx={{ margin: { xs: "0% 10%", sm: 0 } }}
          >
            <Grid container spacing={4}>
              <Grid sm={6} xs={12} mb={2} mr={{ sm: 3, md: 0 }}>
                <Stack
                  direction={"row"}
                  spacing={{ xs: 0.5, sm: 2 }}
                  mb={{ xs: 2, sm: 0 }}
                >
                  <Box
                    component="img"
                    sx={{
                      height: 20,
                      width: 20,
                      maxHeight: { xs: 233, md: 167 },
                      maxWidth: { xs: 350, md: 250 },
                    }}
                    alt="The house from the offer."
                    src={Iconwrap}
                  />
                  <Box>
                    <Typography
                      mb={0.5}
                      sx={{
                        fontFamily: "IBM Plex Sans",
                        color: "var(--gray-900, #101828)",
                        fontWeight: 700,
                      }}
                    >
                      Email
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: "300",
                        fontSize: { xs: "15px" },
                      }}
                    >
                      <a
                        style={{
                          cursor: "pointer",

                          color: "var(--gray-900, #101828)",
                          fontFamily: "Montserrat",
                          textDecoration: "none",
                        }}
                        href="mailto:info@simlesspay.com"
                      >
                        {" "}
                        info@simlesspay.com
                      </a>
                    </Typography>
                  </Box>
                </Stack>
              </Grid>

              <Grid sm={6} xs={12}>
                <Stack
                  direction={"row"}
                  spacing={{ xs: 0.5, sm: 2 }}
                  mb={{ xs: 2, sm: 0 }}
                >
                  <Box
                    component="img"
                    sx={{
                      height: 20,
                      width: 20,
                      maxHeight: { xs: 233, md: 167 },
                      maxWidth: { xs: 350, md: 250 },
                    }}
                    alt="The house from the offer."
                    src={Iconwrap}
                  />
                  <Box>
                    <Typography
                      mb={0.5}
                      sx={{
                        fontFamily: "IBM Plex Sans",
                        color: "var(--gray-900, #101828)",
                        fontWeight: 700,
                      }}
                    >
                      Call
                    </Typography>
                    <a
                      href="tel:+44 734 121 5978"
                      style={{
                        textDecoration: "none",
                        color: "var(--gray-900, #101828)",
                      }}
                    >
                      {" "}
                      <Typography
                        sx={{
                          fontFamily: "Montserrat",
                          fontWeight: "300",
                          fontSize: { xs: "15px" },
                        }}
                      >
                        +44 7860228320
                      </Typography>
                    </a>
                  </Box>
                </Stack>
              </Grid>
              <Grid sm={12} spacing={1} xs={12}>
                <Stack
                  direction={"row"}
                  spacing={{ xs: 0.5, sm: 2 }}
                  mb={{ xs: 2, sm: 0 }}
                >
                  <Box
                    component="img"
                    sx={{
                      height: 20,
                      width: 20,
                      maxHeight: { xs: 233, md: 167 },
                      maxWidth: { xs: 350, md: 250 },
                    }}
                    alt="The house from the offer."
                    src={Iconwrap}
                  />
                  <Box>
                    <Typography
                      mb={0.5}
                      sx={{
                        fontFamily: "IBM Plex Sans",
                        color: "var(--gray-900, #101828)",
                        fontWeight: 700,
                        fontSize: { xs: "15px" },
                      }}
                    >
                      Location
                    </Typography>
                    <a
                      onClick={showMap}
                      style={{
                        cursor: "pointer",
                        fontWeight: 200,
                        color: "var(--gray-900, #101828)",
                        fontFamily: "Montserrat",
                      }}
                    >
                      Location: Cariocca Business Park, 2, Sawley Road
                      Manchester, England, M40 8BB
                    </a>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default ContactUs;
