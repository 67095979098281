import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import Paper from "@mui/material/Paper";
import Slide from "@mui/material/Slide";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Img1 from "../../assets/img/AboutImg/Onboarding-1.png";
import Img2 from "../../assets/img/AboutImg/Onboarding-2.png";
import Img3 from "../../assets/img/AboutImg/Onboarding-3.png";
import Img4 from "../../assets/img/AboutImg/Onboarding-4.png";
import LogIn from "../../assets/img/AboutImg/Log in.png";
import SignUp from "../../assets/img/AboutImg/Sign-up.png";
import Logout from "../../assets/img/AboutImg/Log out.png";
import Money from "../../assets/img/AboutImg/Limit.png";
import Dashboard from "../../assets/img/AboutImg/Homepage.png";
import { Link, useParams } from "react-router-dom";
import "../../assets/img/css/index.css";
import { Button, Card, CardActions, CardContent, CardMedia, Typography } from "@mui/material";
import { Stack } from "@mui/joy";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { decryptData } from "./decrypt";



const BlogDetail = () => {
    let { id } = useParams()

    const [data1, setdata] = useState<any>(null);
    const [loading, setLoading] = useState<any>(false);
    const [Drptdata, setDecryptedData] = useState<any>();
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const response = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/smlWebsiteApi/blogs/${id}`,
                    {
                        headers: {
                            "api-key": process.env.REACT_APP_SECRET_IV,
                        },
                    }
                );
                console.log(response);

                setdata(response?.data?.result);
                setLoading(false)
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (data1) {
            const decryptedData = decryptData(data1);
            setDecryptedData(decryptedData);
            console.log("decrypted data:", decryptedData);
        }
    }, [data1]);

    const handleClick = () => {
        const phoneNumber = "+44 7860228320";
        const message = "Hello World!";
        const whatsappLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(
            message
        )}&phone=${encodeURIComponent(phoneNumber)}`;
        window.open(whatsappLink, "_blank");
    };
    const paragraphs = Drptdata?.content?.split('\n\n');
    const isHTML = (str: string) => /<[a-z][\s\S]*>/i.test(str);

    return (
        <Box sx={{ minHeight: "auto", p: 14 }} >
            {
                loading && <Typography variant="h3" textAlign="center" p={15}>
                    Loading...
                </Typography>

            }
            <Stack spacing={3} >
                <Typography variant="h3" >
                    {Drptdata?.title}
                </Typography>

                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={2}
                    sx={{ mt: 1 }}
                >
                    <div>
                        <Typography variant="subtitle2" mb={1}>
                            {Drptdata?.created_at && new Date(Drptdata?.created_at).toLocaleDateString()}
                        </Typography>

                    </div>
                </Stack>
            </Stack>
            <Box>
                {/* <img src={Drptdata?.image} alt="" /> */}

                <img src={Drptdata?.image} style={{ width: "50%", height: "50%", objectFit: "cover" }} alt="" />
            </Box>

            <Box mt={2}>


                {Drptdata && isHTML(Drptdata.content) ? (
                    <div dangerouslySetInnerHTML={{ __html: Drptdata.content }} /> // Render as HTML
                ) : (
                    paragraphs && paragraphs.map((paragraph: any, index) => (
                        <Typography key={index} variant="body1">
                            {paragraph.split('\n').map((line: any, i) => (
                                <React.Fragment key={i}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))}
                        </Typography>
                    ))
                )}
            </Box>

        </Box>
    );
};

export default BlogDetail;
