import React from "react";
import { Grid, Box, Stack, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Img1 from "../../assets/img/Faq/img1.png";
import Img2 from "../../assets/img/Faq/img2.png";
import Img3 from "../../assets/img/Faq/img3.png";
import Img4 from "../../assets/img/Faq/img4.png";
import Img5 from "../../assets/img/Faq/img5.png";
import Img6 from "../../assets/img/Faq/img6.png";

const OpenRoles = () => {
  const navigate = useNavigate();
  const navigateToAboutUs = () => {
    navigate("/about");
  };
  return (
    <Box position={"relative"}>
      <Box
        sx={{
          padding: "96px 0",
          flexGrow: 1,
          px: { lg: 20, md: 10, sm: 5, xs: 5 },
          py: { xl: 10, xs: 7 },
          //   backgroundColor: "yellowgreen",
          height: { xl: 800 },
        }}
      >
        <Grid container spacing={2}>
          <Grid item sm={4} md={6} xs={12}>
            <Stack direction={"column"} spacing={{ xl: 10, sm: 3 }}>
              <Typography
                sx={{
                  color: "#7C57FC",
                  fontWeight: 600,
                  lineHeight: "24px",
                  fontSize: { sx: "36px", xl: "38px" },
                  marginBottom: { xl: 3 },
                  fontFamily: "Montserrat",
                }}
              >
                Get to know us
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  color: " var(--primary-900, #42307D)",

                  lineHeight: "44px",
                  fontSize: { xs: "20px", sm: "36px", xl: "60px" },
                  fontWeight: 700,
                  letterSpacing: "-0.72px",
                  fontFamily: "IBM Plex Sans",
                }}
              >
                We’re just getting started
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#667085",
                  lineHeight: { sm: "30px", xl: "56px" },
                  fontSize: { sm: "18px", xl: "38px" },
                  fontWeight: "400px",
                  fontFamily: "Montserrat",
                }}
              >
                SimlessPay is growing fast, and we are always looking for
                passionate, dynamic, and talented individuals to join our
                distributed team all around the world. Our philisophy is simple
                — hire a team of diverse, passionate people and foster a culture
                that empowers you to do you best work. Read more about
              </Typography>
              <Stack direction={{ md: "row", sm: "column" }} spacing={2}>
                <Button
                  onClick={navigateToAboutUs}
                  variant="outlined"
                  sx={{
                    fontFamily: "Montserrat",
                    borderRadius: "8px",
                    borderColor: "#D0D5DD",
                    color: "#344054",
                    backgroundColor: "#fff",
                    "&:hover": {
                      backgroundColor: "#fff",
                    },
                    padding: { md: "12px 20px", sm: "6px" },
                    fontSize: { sm: "16px", xl: "20px" },
                  }}
                >
                  About us
                </Button>
                <Button
                  sx={{
                    fontFamily: "Montserrat",
                    borderRadius: "8px",
                    borderColor: "#D0D5DD",
                    color: "#fff",
                    backgroundColor: "#7F56D9",
                    "&:hover": {
                      backgroundColor: "#7F56D9",
                    },
                    padding: { md: "12px 20px", sm: "6px" },
                    fontSize: { sm: "16px", xl: "20px" },
                  }}
                >
                  See open roles
                </Button>
              </Stack>
            </Stack>
          </Grid>
          <Grid item sm={8} md={6} xs={12} height={{ xl: 800 }}>
            <Box
              component="img"
              sx={{
                height: { md: 563, sm: 450, xs: 100 },
                width: { md: 400, sm: 300, xs: 100 },
                left: { md: "68.2%", sm: "60.5%" },
              }}
              alt="The house from the offer."
              src={Img5}
              position={"absolute"}
            />

            <Stack
              direction={"column"}
              spacing={2}
              //   sx={{ backgroundColor: "yellow" }}
            >
              <Stack
                direction={"row"}
                justifyContent={"center"}
                alignItems={"end"}
                // sx={{ backgroundColor: "red" }}
                height={{ xl: 400 }}
                spacing={2}
              >
                <Box
                  component="img"
                  sx={{
                    height: { sm: 160, xl: 200 },
                    width: { sm: 110, xl: 270, md: 145, xs: 80 },
                    maxHeight: { xs: 23300, md: 16700 },
                    maxWidth: { xs: 35000, md: 25000 },
                  }}
                  alt="The house from the offer."
                  src={Img1}
                />
                <Box
                  component="img"
                  sx={{
                    height: { sm: 233, xl: 333 },
                    width: { sm: 110, xl: 270, md: 145, xs: 80 },
                    maxHeight: { xs: 23300, md: 16700 },
                    maxWidth: { xs: 35000, md: 25000 },
                  }}
                  alt="The house from the offer."
                  src={Img2}
                />
              </Stack>
              <Stack
                direction={"row"}
                // sx={{ backgroundColor: "green" }}
                justifyContent={"center"}
                alignItems={"center"}
                height={{ xl: 400 }}
                spacing={2}
              >
                <Box
                  component="img"
                  sx={{
                    height: { sm: 192, xl: 292 },
                    width: { md: 140, sm: 120, xl: 240, xs: 80 },
                    maxHeight: { xs: 23300, md: 167000 },
                    maxWidth: { xs: 30050, md: 25000 },
                  }}
                  alt="The house from the offer."
                  src={Img3}
                />
                <Box
                  component="img"
                  sx={{
                    height: { sm: 243, xl: 343 },
                    width: { md: 135, sm: 110, xl: 235, xs: 80 },
                    maxHeight: { xs: 23300, md: 167000 },
                    maxWidth: { xs: 30050, md: 25000 },
                  }}
                  alt="The house from the offer."
                  src={Img4}
                />
                <Box
                  component="img"
                  sx={{
                    height: { sm: 192, xl: 292 },
                    width: { md: 140, sm: 120, xl: 240, xs: 80 },
                    maxHeight: { xs: 23377, md: 16799 },
                    maxWidth: { xs: 35088, md: 25099 },
                  }}
                  alt="The house from the offer."
                  src={Img6}
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default OpenRoles;
